import './App.scss';
import { useTranslation } from 'react-i18next';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getAllPages } from '../../api/pagesApi';
import { useLoginCheck } from '../../hooks/useLoginCheck';
import { useWebPortalStore } from '../../store/WebPortalStore';
import { Page } from '../../interfaces/Page';
import { usePharmacyData } from '../../hooks/usePharmacy';
import Login from '../../pages/Login/Login';
import PrivateRoute from '../../layouts/PrivateRoute';
import AccountLanguage from '../../pages/AccountLanguage/AccountLanguage';
import EpicIframe from '../EpicIframe/EpicIframe';
import PublicFormPage from '../../pages/PublicFormPage/PublicFormPage';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import HelpModal from '../HelpModal/HelpModal';
import Home from '../../pages/Home/Home';
import Dashboard from '../../layouts/Dashboard';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import Messages from '../../pages/Messages/Messages';
import Claims from '../../pages/Claims/Claims';
import PrivateFormPage from '../../pages/PrivateFormPage/PrivateFormPage';
import Coverage from '../../pages/Coverage/Coverage';
import Account from '../../pages/Account/Account';
import useHandleRouteChange from '../../hooks/useHandleRouteChange';
import useBreakOutOfIframe from '../../hooks/useBreakOutOfIframe';
import PremiumBilling from '../../pages/PremiumBilling/PremiumBilling';
import ManageAccess from '../../pages/ManageAccess';
import useEpicApiStore from '../../store/useEpicApiStore';
import AccountForms from '../../pages/AccountForms/AccountForms';
import CoverageDocuments from '../../pages/CoverageDocuments/CoverageDocuments';
import CoverageForms from '../../pages/CoverageForms/CoverageForms';
import FindCare from '../../pages/FindCare/FindCare';
import CareForms from '../../pages/CareForms/CareForms';
import PreventionAndWellness from '../../pages/PreventionAndWellness/PreventionAndWellness';
import PharmacyForms from '../../pages/PharmacyForms/PharmacyForms';
import Pharmacy from '../../pages/Pharmacy/Pharmacy';
import PayNow from '../../pages/PayNow/PayNow';
import ViewMyPlans from '../../pages/ViewMyPlans/ViewMyPlans';
import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import ProxyInvite from '../../pages/ProxyInvite/ProxyInvite';
import DocumentCenter from '../../pages/DocumentCenter/DocumentCenter';
import ChangeMyPlan from '../../pages/ChangeMyPlan/ChangeMyPlan';
import BehavioralHealth from '../../pages/BehavioralHealth/BehavioralHealth';

function App() {
  const { hasMedicare, premium, filingOrder, paperlessBilling } =
    useEpicApiStore();
  const pharmacy = usePharmacyData();
  const { isModalOpen, isMobileMenuOpen } = useWebPortalStore();
  const { hasLoaded } = useLoginCheck();
  const { t, i18n } = useTranslation();
  const locale = i18n.resolvedLanguage ?? 'en';

  const { data: pages, isLoading } = useQuery({
    queryKey: ['pages', locale],

    queryFn: () => getAllPages(locale),
  });

  useHandleRouteChange();
  useBreakOutOfIframe();

  return (
    <div className={`App ${isMobileMenuOpen ? '-is-mobile-menu-open' : ''}`}>
      {isLoading || !hasLoaded ? (
        <LoadingSpinner />
      ) : (
        <Routes>
          {/* Private Routes */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route path="" element={<Home />} />
            <Route path={`${t('routes.home')}`} element={<Home />} />
            <Route
              path={`${t('routes.coverage.viewMyPlans')}`}
              element={<ViewMyPlans />}
            />
            <Route
              path={`${t('routes.grievanceForm')}`}
              element={
                <PrivateFormPage
                  title={`${t('routes.grievanceForm')}`}
                  src="inside.asp?mode=questionnaire&src=sys&srcid=1&quesid=1401000901"
                />
              }
            />
            {/* Coverages */}
            <Route path={`${t('routes.coverage.index')}`}>
              <Route index element={<Coverage />} />
              <Route
                path={`${t('routes.coverage.wellness')}`}
                element={<PreventionAndWellness />}
              />
              <Route
                path={`${t('routes.coverage.behavioralHealth')}`}
                element={<BehavioralHealth />}
              />
              <Route
                path={`${t('routes.coverage.referrals')}`}
                element={
                  <PrivateFormPage
                    title={t('coverage.referrals')}
                    src="inside.asp?mode=referrals&cvgId=0&eptId=0"
                    height={700}
                  />
                }
              />
              <Route
                path={`${t('routes.coverage.precertifications')}`}
                element={
                  <PrivateFormPage
                    title={t('coverage.precertifications')}
                    src="inside.asp?mode=referrals&cvgId=0&eptId=0"
                    height={700}
                  />
                }
              />
              <Route
                path={`${t('routes.coverage.balanceDetails')}`}
                element={
                  <PrivateFormPage
                    title={t('routes.coverage.balanceDetails')}
                    src={`inside.asp?mode=benefitinfo&action=outofpocketben&cvgId=${filingOrder}&eptId=0`}
                    height={700}
                  />
                }
              />
              <Route
                path={`${t('routes.coverage.meteredBenefits')}`}
                element={
                  <PrivateFormPage
                    title={t('routes.coverage.meteredBenefits')}
                    src={`inside.asp?mode=benefitinfo&action=meteredben&cvgId=${filingOrder}&eptId=0`}
                    height={700}
                  />
                }
              />
              <Route
                path={`${t('routes.coverage.coveredMembers')}`}
                element={
                  <PrivateFormPage
                    title={t('coverage.coveredMembers')}
                    src={`inside.asp?mode=coverage&cvgId=${filingOrder}`}
                    height={700}
                  />
                }
              />
              <Route
                path={`${t('routes.coverage.documents')}`}
                element={<CoverageDocuments />}
              />
              <Route path={`${t('routes.coverage.forms')}`}>
                <Route index element={<CoverageForms />} />
                <Route
                  path={`${t('routes.coverage.dependentsAdd')}`}
                  element={
                    <PrivateFormPage
                      title={t('coverage.dependentsAdd')}
                      src="inside.asp?mode=adddep"
                      height={1750}
                    />
                  }
                />
                <Route
                  path={`${t('routes.coverage.dependentsRemove')}`}
                  element={
                    <PrivateFormPage
                      title={t('coverage.dependentsRemove')}
                      src="inside.asp?mode=termdep"
                      height={1750}
                    />
                  }
                />
                <Route
                  path={`${t('routes.coverage.terminate')}`}
                  element={
                    <PrivateFormPage
                      title={t('coverage.terminate')}
                      src="inside.asp?mode=termcvg"
                      height={700}
                    />
                  }
                />
                <Route
                  path={`${t('routes.coverage.changeMyPlan')}`}
                  element={<ChangeMyPlan />}
                />
              </Route>
              <Route
                path={`${t('routes.coverage.requestIdCard')}`}
                element={
                  <PrivateFormPage
                    title={t('coverage.card.title')}
                    src="inside.asp?mode=showform&formname=idCardRequest"
                    height={700}
                  />
                }
              />
            </Route>
            {/* Account */}
            <Route path={`${t('routes.account.index')}`}>
              <Route index element={<Account />} />
              <Route
                path={`${t('routes.account.manageAccess')}`}
                element={<ManageAccess />}
              />
              <Route
                path={`${t('routes.account.changePassword')}`}
                element={
                  <PrivateFormPage
                    title={`${t('account.changePassword.title')}`}
                    src="inside.asp?mode=passwd"
                    height={700}
                  />
                }
              />
              <Route
                path={`${t('routes.account.letters')}`}
                element={
                  <PrivateFormPage
                    title={`${t('account.letters.title')}`}
                    src="inside.asp?mode=letters"
                    height={460}
                  />
                }
              />
              <Route
                path={`${t('routes.account.deactivate')}`}
                element={
                  <PrivateFormPage
                    title={`${t('account.deactivate.title')}`}
                    src="app/account-management/deactivate-account"
                    height={460}
                  />
                }
              />
              <Route
                path={`${t('routes.account.notifications')}`}
                element={
                  <PrivateFormPage
                    title={`${t('account.notifications.title')}`}
                    src="preferences"
                    height={1400}
                  />
                }
              />
              <Route path={`${t('routes.account.documentCenter')}`}>
                <Route index element={<DocumentCenter />} />
                <Route
                  path={`${t('routes.account.correspondence')}`}
                  element={
                    <PrivateFormPage
                      title={`${t('account.correspondence.title')}`}
                      src="app/document-center/my-documents"
                      height={350}
                      setTitleFormat={false}
                    />
                  }
                />
              </Route>
              <Route
                path={`${t('routes.account.languages')}`}
                element={<AccountLanguage />}
              />
              <Route path={`${t('routes.account.forms.index')}`}>
                <Route index element={<AccountForms />} />
                <Route
                  path={`${t('routes.account.forms.contactInformation')}`}
                  element={
                    <PrivateFormPage
                      title={t('routes.account.forms.contactInformation')}
                      src="PersonalInformation"
                      height={700}
                    />
                  }
                />
                <Route
                  path={`${t('routes.account.forms.planName')}`}
                  element={
                    <PrivateFormPage
                      title={t('routes.account.forms.planName')}
                      src="inside.asp?mode=questionnaire&src=sys&srcid=1&quesid=1401000004"
                      height={700}
                    />
                  }
                />
                <Route
                  path={`${t('routes.account.forms.ssnVerification')}`}
                  element={
                    <PrivateFormPage
                      title={t('routes.account.forms.ssnVerification')}
                      src="/inside.asp?mode=fdiredirect&option=ssnVerification"
                      height={700}
                    />
                  }
                />
                <Route
                  path={`${t('routes.account.forms.ssnCorrection')}`}
                  element={
                    <PrivateFormPage
                      title={t('account.ssnCorrection.title')}
                      src="/inside.asp?mode=fdiredirect&option=ssnCorrect"
                      height={700}
                    />
                  }
                />
              </Route>
            </Route>
            {/* Messages */}
            <Route path={`${t('routes.messages')}`} element={<Messages />} />
            {/* Balances */}
            <Route
              path={`${t('routes.balances')}`}
              element={
                <PrivateFormPage
                  title={`${t('routes.balances')}`}
                  src="inside.asp?mode=benefitinfo&cvgId=0&eptId=0"
                />
              }
            />
            {/* Notifications */}
            <Route
              path={`${t('routes.notifications')}`}
              element={
                <PrivateFormPage
                  title={`${t('routes.notifications')}`}
                  src="Home"
                />
              }
            />
            {/* Claims */}
            <Route path={`${t('routes.claims.index')}`}>
              <Route index element={<Claims />} />
              <Route
                path={`${t('routes.claims.medicalReimbursement')}`}
                element={
                  <PrivateFormPage
                    title={t(
                      'claims.resourceSection.links.medicalReimbursement',
                    )}
                    src="inside.asp?mode=questionnaire&src=sys&srcid=1&quesid=1401000900"
                  />
                }
              />
              <Route
                path={`${t('routes.claims.forms.atHomeCovid19')}`}
                element={
                  <PrivateFormPage
                    title={t('claims.resourceSection.links.atHomeCovid19')}
                    src="inside.asp?mode=questionnaire&src=sys&srcid=1&quesid=330518791"
                  />
                }
              />
            </Route>
            {/* Find care */}
            <Route path={`${t('routes.findCare.index')}`}>
              <Route index element={<FindCare />} />
              <Route
                path={`${t('routes.findCare.careTeam')}`}
                element={
                  <PrivateFormPage
                    title={`${t('routes.findCare.careTeam')}`}
                    src="clinical/careteam"
                  />
                }
              />
              <Route
                path={`${t('routes.findCare.healthLibrary')}`}
                element={
                  <PrivateFormPage
                    title={`${t('routes.findCare.healthLibrary')}`}
                    src="Extensibility/Redirection/Content?fdi=WP-24QY2ERtPPk7iy62X7a1D97g-3D-3D-248QESRRUAuOnRzn1sDJO166XkoZpuJinIvPGnjeuX7B4-3D"
                  />
                }
              />
              <Route path={`${t('routes.findCare.forms')}`}>
                <Route index element={<CareForms />} />
                <Route
                  path={`${t('routes.findCare.caseManagementReferral')}`}
                  element={
                    <PrivateFormPage
                      title={`${t('routes.findCare.caseManagementReferral')}`}
                      src="inside.asp?mode=questionnaire&src=sys&srcid=1&quesid=1401000903"
                    />
                  }
                />
                <Route
                  path={`${t('routes.findCare.diseaseManagementReferral')}`}
                  element={
                    <PrivateFormPage
                      title={`${t(
                        'routes.findCare.diseaseManagementReferral',
                      )}`}
                      src="inside.asp?mode=questionnaire&src=sys&srcid=1&quesid=1401000902"
                    />
                  }
                />
              </Route>
            </Route>
            {/* Change PCP */}
            <Route
              path={`${t('routes.changePcp')}`}
              element={
                <PrivateFormPage
                  title={`${t('routes.changePcp')}`}
                  src="inside.asp?mode=chgpcp"
                  height={700}
                />
              }
            />
            {/* Billing */}
            <Route path={`${t('routes.billing.index')}`}>
              <Route
                index
                element={premium?.hasBilling && <PremiumBilling />}
              />
              <Route
                path={`${t('routes.billing.guestPay')}`}
                element={
                  premium?.hasBilling && (
                    <PrivateFormPage
                      title={t('routes.billing.guestPay')}
                      src="Insurance/Guestpay"
                    />
                  )
                }
              />
              <Route
                path={`${t('routes.billing.payNow')}`}
                element={premium?.hasBilling && <PayNow />}
              />
              <Route
                path={`${t('routes.billing.autoPay')}`}
                element={
                  premium?.hasBilling && (
                    <PrivateFormPage
                      title={t('routes.billing.autoPay')}
                      src={`Insurance/PremiumAutoPay/Start?accountId=${premium?.pbaEncryptedId}`}
                    />
                  )
                }
              />
              <Route
                path={`${t('routes.billing.pastPayments')}`}
                element={
                  premium?.hasBilling && (
                    <PrivateFormPage
                      title={t('routes.billing.pastPayments')}
                      src={`Insurance/PremiumBilling/Payments?pbaID=${premium?.pbaEncryptedId}`}
                    />
                  )
                }
              />
              <Route
                path={`${t('routes.billing.paperlessBilling')}`}
                element={
                  paperlessBilling && (
                    <PrivateFormPage
                      title={t('routes.billing.paperlessBilling')}
                      src={`Paperless/Invoice/?fromPB=1`}
                    />
                  )
                }
              />
              <Route
                path={`${t('routes.billing.billingHistory')}`}
                element={
                  premium?.hasBilling && (
                    <PrivateFormPage
                      title={t('routes.billing.billingHistory')}
                      src={`app/premium-billing/invoice-history?pbaId=${premium?.pbaEncryptedId}`}
                      excerpt={
                        !hasMedicare
                          ? 'billing.billingHistory.excerpt'
                          : 'billing.billingHistory.excerptMedicare'
                      }
                    />
                  )
                }
              />
            </Route>

            {/* Pharmacy */}
            <Route path={`${t('routes.pharmacy.index')}`}>
              <Route index element={pharmacy?.hasPharmacy && <Pharmacy />} />
              <Route
                path={`${t('routes.pharmacy.forms')}`}
                element={pharmacy?.hasPharmacy && <PharmacyForms />}
              />
            </Route>

            {/* Support */}
            <Route path={`${t('routes.support.index')}`}>
              <Route
                index
                element={
                  <PrivateFormPage
                    title={`${t('routes.support.index')}`}
                    src="app/communication-center/customer-service"
                    height={700}
                  />
                }
              />
            </Route>

            {/* Proxy invite */}
            <Route
              path={`${t('routes.proxyInvite')}`}
              element={<ProxyInvite />}
            />

            {pages &&
              pages.map((item: Page) => (
                <Route
                  key={item.Id}
                  path={item.RelativeUrlPath}
                  element={
                    <BreadcrumbLayout pageTitle={item.Title}>
                      <p className="container">Page needs to be built.</p>
                    </BreadcrumbLayout>
                  }
                />
              ))}
          </Route>

          {/* Public Routes */}
          <Route path="/login">
            <Route index element={<Login />} />
            <Route
              element={
                <PublicFormPage>
                  <Outlet />
                </PublicFormPage>
              }
            >
              <Route
                path="recover-username"
                element={
                  <EpicIframe
                    title="Recover Username"
                    url="recoverlogin.asp?mobile=1"
                    width={500}
                    height={850}
                  />
                }
              />
              <Route
                path="reset-password"
                element={
                  <EpicIframe
                    title="Password Reset"
                    url="passwordreset.asp?mobile=1"
                    width={500}
                    height={650}
                  />
                }
              />
              <Route
                path="code"
                element={
                  <EpicIframe
                    title="SHP Code"
                    url="accesscheck.asp?mobile=1"
                    width={500}
                    height={600}
                  />
                }
              />
              <Route
                path="code-request"
                element={
                  <EpicIframe
                    title="SHP Code"
                    url="Signup?mobile=1"
                    width={500}
                    height={1940}
                  />
                }
              />
              <Route
                path="guest-pay"
                element={
                  <EpicIframe
                    title="Guest Pay"
                    url="Insurance/Guestpay"
                    width={500}
                    height={1100}
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path="contact-us"
            element={
              <PublicFormPage>
                <EpicIframe
                  title="SHP Code"
                  url="publicforms.asp?mode=showform&formname=contactUsPublicForm"
                  width={500}
                  height={1730}
                />
              </PublicFormPage>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
      {isModalOpen && <HelpModal />}
    </div>
  );
}

export default App;
