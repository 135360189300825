import { useEffect } from 'react';

const useBreakOutOfIframe = () => {
  useEffect(() => {
    const inIframe = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };

    if (inIframe()) {
      if (window.top) {
        window.top.location.href = window.self.location.href;
      }
    }
  }, []);
};

export default useBreakOutOfIframe;
