import { useEffect, useState } from 'react';
import axios from 'axios';
import { getEpicUrl } from '../helpers/urlUtils';

const useFetchBillDownloadLink = () => {
  const [link, setLink] = useState<string | null>(null);

  useEffect(() => {
    const fetchBillDownloadLink = async () => {
      try {
        axios.defaults.withCredentials = true;
        const epicUrl = getEpicUrl();
        const response = await axios.get(`${epicUrl}Insurance/PremiumBilling`, {
          responseType: 'text',
        });
        const parser = new DOMParser();
        const html = parser.parseFromString(response.data, 'text/html');
        const el = html.querySelector('.premium-bill-download-button');

        if (el) {
          setLink(
            `${epicUrl}Insurance/PremiumBilling/DisplayInvoice/?fileName=${el?.getAttribute(
              'data-filename',
            )}&invNum=${el?.getAttribute(
              'data-invnum',
            )}&token=${el?.getAttribute('data-token')}`,
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchBillDownloadLink();
  }, []);

  return link;
};

export default useFetchBillDownloadLink;
