import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFHIRAuth from '../hooks/useFHIRAuth';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import DashboardHeader from '../components/DashboardHeader/DashboardHeader';
import DashboardFooter from '../components/DashboardFooter/DashboardFooter';
import PrivateRoute from './PrivateRoute';
import FooterBadges from '../components/FooterBadges/FooterBadges';
import useEpicApiStore from '../store/useEpicApiStore';
import DualCoverage from '../components/DualCoverage/DualCoverage';
import useHandleExternalLinks from '../hooks/useHandleExternalLinks';
import { SetStateAction, useEffect, useState } from 'react';
import ExternalLinkModal from '../components/ExternalLinkModal/ExternalLinkModal';
import { useWebPortalStore } from '../store/WebPortalStore';
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setPostLoginRedirect } = useWebPortalStore();
  const { coverages, isCoverageSelected, noPlansExperience } =
    useEpicApiStore();
  const { authorizeToken, error } = useFHIRAuth();
  const { hasMedicare } = useEpicApiStore();
  const [showModal, setShowModal] = useState(false);
  const [clickedExternalUrl, setClickedExternalUrl] = useState<string | null>(
    null,
  );

  useHandleExternalLinks(hasMedicare, (url: SetStateAction<string | null>) => {
    setClickedExternalUrl(url);
    setShowModal(true);
  });

  useEffect(() => {
    const routesToMatch = [
      t('routes.home'),
      t('routes.claims.index'),
      t('routes.account.index'),
      t('routes.account.documentCenter'),
      t('routes.messages'),
      t('routes.notifications'),
      t('routes.support.index'),
    ];

    const routePattern = new RegExp(routesToMatch.join('|'));

    if (!window.location.pathname.match(routePattern) && noPlansExperience) {
      setPostLoginRedirect(undefined);
      navigate(`/${t('routes.home')}`);
    }
  }, [t, noPlansExperience, setPostLoginRedirect, navigate]);

  if (!authorizeToken && !error) {
    return <LoadingSpinner message={t('authorize.loading') ?? ''} />;
  }

  if (!isCoverageSelected && coverages && coverages.length > 1) {
    return <DualCoverage />;
  }

  return (
    <PrivateRoute>
      <div className="dashboard">
        <DashboardHeader />
        <Outlet />
        <FooterBadges />
        <DashboardFooter />

        {showModal && (
          <ExternalLinkModal
            clickedExternalUrl={clickedExternalUrl || ''}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )}
      </div>
    </PrivateRoute>
  );
}
