import { useTranslation } from 'react-i18next';

const IconGuestPay = () => {
  const { t } = useTranslation();

  return (
    <svg
      width="56"
      height="31"
      viewBox="0 0 56 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.guestPay')}</title>
      <g clipPath="url(#clip0_1753_9129)">
        <path
          d="M50.0344 25.3363H0.855288C0.38488 25.3363 0 24.9502 0 24.4782V0.858132C0 0.386159 0.38488 0 0.855288 0H50.0344C50.5048 0 50.8896 0.386159 50.8896 0.858132V24.4782C50.8896 24.9502 50.5048 25.3363 50.0344 25.3363ZM1.71058 23.6201H49.1791V1.71626H1.71058V23.6201Z"
          fill="#00548E"
        />
        <path
          d="M52.472 28.3184H3.29291C2.8225 28.3184 2.43762 27.9322 2.43762 27.4603C2.43762 26.9883 2.8225 26.6021 3.29291 26.6021H51.6167V3.84019C51.6167 3.36821 52.0016 2.98206 52.472 2.98206C52.9424 2.98206 53.3273 3.36821 53.3273 3.84019V27.4603C53.3273 27.9322 52.9424 28.3184 52.472 28.3184Z"
          fill="#00548E"
        />
        <path
          d="M55.1447 31.0001H5.96564C5.49523 31.0001 5.11035 30.6139 5.11035 30.1419C5.11035 29.67 5.49523 29.2838 5.96564 29.2838H54.2894V6.54331C54.2894 6.07134 54.6743 5.68518 55.1447 5.68518C55.6151 5.68518 56 6.07134 56 6.54331V30.1419C56 30.6139 55.6151 31.0001 55.1447 31.0001Z"
          fill="#00548E"
        />
        <path
          d="M25.4448 22.8907C21.1684 22.8907 17.6831 18.4284 17.6831 12.9364C17.6831 7.44434 21.1684 2.98206 25.4448 2.98206C29.7213 2.98206 33.2066 7.44434 33.2066 12.9364C33.2066 18.4284 29.7213 22.8907 25.4448 22.8907ZM25.4448 4.69832C22.1092 4.69832 19.3937 8.38829 19.3937 12.9364C19.3937 17.4845 22.1092 21.1744 25.4448 21.1744C28.7805 21.1744 31.496 17.4845 31.496 12.9364C31.496 8.38829 28.7805 4.69832 25.4448 4.69832Z"
          fill="#00548E"
        />
        <path
          d="M0.855288 8.64568C0.38488 8.64568 0 8.25952 0 7.78754C0 7.31557 0.38488 6.92941 0.855288 6.92941C4.19091 6.92941 6.90645 4.20484 6.90645 0.858132C6.90645 0.386159 7.29133 0 7.76174 0C8.23215 0 8.61703 0.386159 8.61703 0.858132C8.61703 5.14879 5.13173 8.64568 0.855288 8.64568Z"
          fill="#00548E"
        />
        <path
          d="M50.0343 8.64568C45.7579 8.64568 42.2726 5.14879 42.2726 0.858132C42.2726 0.386159 42.6575 0 43.1279 0C43.5983 0 43.9832 0.386159 43.9832 0.858132C43.9832 4.20484 46.6987 6.92941 50.0343 6.92941C50.5047 6.92941 50.8896 7.31557 50.8896 7.78754C50.8896 8.25952 50.5047 8.64568 50.0343 8.64568Z"
          fill="#00548E"
        />
        <path
          d="M7.76174 25.3363C7.29133 25.3363 6.90645 24.9502 6.90645 24.4782C6.90645 21.1315 4.19091 18.4069 0.855288 18.4069C0.38488 18.4069 0 18.0208 0 17.5488C0 17.0768 0.38488 16.6907 0.855288 16.6907C5.13173 16.6907 8.61703 20.1876 8.61703 24.4782C8.61703 24.9502 8.23215 25.3363 7.76174 25.3363Z"
          fill="#00548E"
        />
        <path
          d="M43.1279 25.3363C42.6575 25.3363 42.2726 24.9502 42.2726 24.4782C42.2726 20.1876 45.7579 16.6907 50.0343 16.6907C50.5047 16.6907 50.8896 17.0768 50.8896 17.5488C50.8896 18.0208 50.5047 18.4069 50.0343 18.4069C46.6987 18.4069 43.9832 21.1315 43.9832 24.4782C43.9832 24.9502 43.5983 25.3363 43.1279 25.3363Z"
          fill="#00548E"
        />
        <path
          d="M13.8342 12.3356H4.8537C4.38329 12.3356 3.99841 11.9495 3.99841 11.4775C3.99841 11.0055 4.38329 10.6194 4.8537 10.6194H13.8342C14.3046 10.6194 14.6895 11.0055 14.6895 11.4775C14.6895 11.9495 14.3046 12.3356 13.8342 12.3356Z"
          fill="#00548E"
        />
        <path
          d="M13.8342 15.5537H4.8537C4.38329 15.5537 3.99841 15.1675 3.99841 14.6955C3.99841 14.2236 4.38329 13.8374 4.8537 13.8374H13.8342C14.3046 13.8374 14.6895 14.2236 14.6895 14.6955C14.6895 15.1675 14.3046 15.5537 13.8342 15.5537Z"
          fill="#00548E"
        />
        <path
          d="M45.2234 12.3356H36.2642C35.7938 12.3356 35.4089 11.9495 35.4089 11.4775C35.4089 11.0055 35.7938 10.6194 36.2642 10.6194H45.2234C45.6938 10.6194 46.0787 11.0055 46.0787 11.4775C46.0787 11.9495 45.6938 12.3356 45.2234 12.3356Z"
          fill="#00548E"
        />
        <path
          d="M45.2234 15.5537H36.2642C35.7938 15.5537 35.4089 15.1675 35.4089 14.6955C35.4089 14.2236 35.7938 13.8374 36.2642 13.8374H45.2234C45.6938 13.8374 46.0787 14.2236 46.0787 14.6955C46.0787 15.1675 45.6938 15.5537 45.2234 15.5537Z"
          fill="#00548E"
        />
        <path
          d="M28.2886 14.6956C28.2886 15.2962 28.0748 15.8111 27.6258 16.1973C27.1768 16.5834 26.5353 16.8409 25.7014 16.9267V18.2997H24.8675V16.9696C23.8411 16.9696 22.9858 16.798 22.323 16.4976V15.1675C22.6865 15.3392 23.0928 15.4893 23.5845 15.618C24.055 15.7468 24.504 15.7897 24.8675 15.8111V13.4513L24.3329 13.2582C23.6487 13.0007 23.1569 12.6789 22.8362 12.3142C22.5154 11.9495 22.3658 11.499 22.3658 10.9627C22.3658 10.3834 22.601 9.91147 23.05 9.52531C23.499 9.16061 24.0977 8.92462 24.8675 8.83881V7.7876H25.7014V8.81736C26.5567 8.83881 27.3478 9.01043 28.1176 9.33223L27.6686 10.4907C27.0057 10.2333 26.3642 10.0831 25.7228 10.0187V12.3142L26.1932 12.4859C26.9843 12.7862 27.5403 13.108 27.8396 13.4513C28.139 13.7945 28.2886 14.2021 28.2886 14.6956ZM23.8411 10.9198C23.8411 11.1558 23.9053 11.3488 24.055 11.5205C24.2046 11.6706 24.4612 11.8423 24.8675 11.9924V10.0402C24.5254 10.0831 24.2688 10.1904 24.0977 10.3405C23.9267 10.4907 23.8411 10.6838 23.8411 10.9198ZM26.8133 14.7814C26.8133 14.5454 26.7277 14.3309 26.5567 14.1807C26.3856 14.0305 26.1077 13.8803 25.7014 13.7302V15.7253C26.4284 15.618 26.8133 15.2962 26.8133 14.7814Z"
          fill="#00548E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1753_9129">
          <rect width="56" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconGuestPay;
