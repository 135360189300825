import './NoPlansExperience.scss';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../../store/useEpicApiStore';
import SupportCard from '../../components/SupportCard/SupportCard';

type Props = {};

const NoPlansExperience = (props: Props) => {
  const { t } = useTranslation();
  const { userDetails } = useEpicApiStore();

  return (
    <section className="no-plans" data-testid="no-plans">
      <div className="container">
        <h1 className="no-plans__title">
          {t('dashboardNav.accountGreeting', {
            name: userDetails?.firstName || t('dashboardNav.account'),
          })}
        </h1>
        <p className="no-plans__text">{t('noPlansExperience.text')}</p>
      </div>

      <div className="no-plans__cta">
        <div className="container">
          <SupportCard />
        </div>
      </div>
    </section>
  );
};

export default NoPlansExperience;
