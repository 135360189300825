import './LanguageSettings.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setLangText } from '../../helpers/setLangText';
import ScrollToLink from '../ScrollToLink/ScrollTolink';

const LanguageSettings: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18n.resolvedLanguage ?? 'en',
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

  const languages: [] = t('account.languageSettings.languages', {
    returnObjects: true,
  });

  const handleInput = (lang: string) => {
    setSelectedLanguage(lang);
    setIsSaveDisabled(lang === i18n.resolvedLanguage);
  };

  const handleSaveChanges = () => {
    if (selectedLanguage !== i18n.resolvedLanguage) {
      i18n.changeLanguage(selectedLanguage);
      navigate(
        `/${t('routes.account.index')}/${t('routes.account.languages')}`,
        {
          replace: true,
        },
      );
      setIsSaveDisabled(true);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLLabelElement>,
    locale: string,
  ) => {
    if (event.key === 'Enter') {
      handleInput(locale);
    }
  };

  return (
    <div className="language-settings">
      <div className="language-settings__options">
        {languages.map((locale, index) => (
          <label
            key={index}
            className="language-settings__option"
            tabIndex={0}
            onKeyDown={event => handleKeyDown(event, locale)}
          >
            {setLangText(locale)}
            <input
              type="radio"
              id={locale}
              name="Language"
              value={locale}
              className="language-settings__input"
              checked={selectedLanguage === locale}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                handleInput(locale)
              }
              tabIndex={-1}
            />
          </label>
        ))}
      </div>
      <div className="language-settings__controls">
        <button
          className="button language-settings__save-btn"
          disabled={isSaveDisabled}
          onClick={handleSaveChanges}
        >
          {t('account.languageSettings.save')}
        </button>
        <div className="language-settings__link">
          <ScrollToLink targetId="help-in-other-languages" behavior="smooth">
            {t('account.languageSettings.otherLanguages')}
          </ScrollToLink>
        </div>
      </div>
    </div>
  );
};

export default LanguageSettings;
