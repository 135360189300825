import React from 'react';
import './AccountLanguage.scss';
import { useTranslation } from 'react-i18next';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import LanguageSettings from '../../components/LanguageSettings/LanguageSettings';
import PersonalUpdates from '../../components/PersonalUpdates/PersonalUpdates';

export default function AccountLanguage() {
  const { t } = useTranslation();

  return (
    <div data-testid="account-language">
      <BreadcrumbLayout
        pageTitle={t('account.languageSettings.title')}
        className="account-language"
      >
        <>
          <div className="account-language__settings">
            <div className="container">
              <LanguageSettings />
            </div>
          </div>
          <div className="container -personal-updates">
            <PersonalUpdates hasHeading={true} />
          </div>
        </>
      </BreadcrumbLayout>
    </div>
  );
}
