/**
 * Helpers for manipulating URLs.
 */

/**
 * Determine EPIC URL based on environment.
 * @returns string
 */
const getEpicUrl = () => {
  const env = process.env;
  let urlValue = env.REACT_APP_EPIC_BASE_URL_DEV;
  const origin = window.location.origin;

  if ('https://stage.member.aws-nonprod.sharphealthplan.com' === origin) {
    return env.REACT_APP_EPIC_BASE_URL_STAGE;
  } else if ('https://rel.member.aws-nonprod.sharphealthplan.com' === origin) {
    return env.REACT_APP_EPIC_BASE_URL_REL;
  } else if (env.REACT_APP_PROD_DOMAIN === origin) {
    return env.REACT_APP_EPIC_BASE_URL_PROD;
  }

  return urlValue;
};

/**
 * Determine EPIC FDI URL based on environment.
 * @returns string
 */
const getFDIUrl = () => {
  const env = process.env;
  const host = getEpicUrl();
  let optionValue = 'modeaLocal';

  const origin = window.location.origin;
  const prodDomain = env.REACT_APP_PROD_DOMAIN;

  if (getServerUrl('dev') === origin) {
    optionValue = 'modeaDev';
  } else if (getServerUrl('stage') === origin) {
    optionValue = 'modeaStage';
  } else if (getServerUrl('rel') === origin) {
    optionValue = 'modeaRel';
  } else if (prodDomain === origin) {
    optionValue = 'SmartOnFHIR';
  }

  return `${host}inside.asp?mode=fdiredirect&option=${optionValue}`;
};

const getServerUrl = (server: string) => {
  switch (server) {
    case 'dev':
      return 'https://dev.member.aws-nonprod.sharphealthplan.com';
    case 'stage':
      return 'https://stage.member.aws-nonprod.sharphealthplan.com';
    case 'rel':
      return 'https://rel.member.aws-nonprod.sharphealthplan.com';
    case 'prod':
      return process.env.REACT_APP_PROD_DOMAIN || '';
    default:
      return 'https://localhost:3000';
  }
};

/**
 * Determine FHIR Authorization URL/Client ID based on environment.
 * @returns string
 */
const getFHIRAuthCredentials = () => {
  const env = process.env;
  let FHIR_URL, CLIENT_ID;

  if (env.REACT_APP_PROD_DOMAIN === window.location.origin) {
    FHIR_URL = env.REACT_APP_FHIR_AUTH_BASE_URL_PROD;
    CLIENT_ID = env.REACT_APP_FHIR_CLIENT_ID_PROD;
  } else if (env.REACT_APP_REL_DOMAIN === window.location.origin) {
    FHIR_URL = env.REACT_APP_FHIR_AUTH_BASE_URL_REL;
    CLIENT_ID = env.REACT_APP_FHIR_CLIENT_ID_REL;
  } else {
    FHIR_URL = env.REACT_APP_FHIR_AUTH_BASE_URL_STAGE;
    CLIENT_ID = env.REACT_APP_FHIR_CLIENT_ID_STAGE;
  }

  return { FHIR_URL, CLIENT_ID };
};

/**
 * Determine EPIC API based on environment.
 * @returns string
 */
const getEpicAPIUrl = () => {
  const env = process.env;
  let EPIC_API_URL = env.REACT_APP_EPIC_API_BASE_URL_STAGE;

  if (env.REACT_APP_PROD_DOMAIN === window.location.origin) {
    EPIC_API_URL = env.REACT_APP_EPIC_API_BASE_URL_PROD;
  } else if (env.REACT_APP_REL_DOMAIN === window.location.origin) {
    EPIC_API_URL = env.REACT_APP_EPIC_API_BASE_URL_REL;
  }

  return { EPIC_API_URL };
};

/**
 * Determine Which redirect option to send with proxy switch requests.
 * @returns string
 */
const getEpicProxyRedirectValue = () => {
  const env = process.env;
  let optionValue = 'homePageLocal';

  const origin = window.location.origin;
  const prodDomain = env.REACT_APP_PROD_DOMAIN;

  if (getServerUrl('dev') === origin) {
    optionValue = 'homePageDev';
  } else if (getServerUrl('stage') === origin) {
    optionValue = 'homePageStage';
  } else if (getServerUrl('rel') === origin) {
    optionValue = 'homePageRel';
  } else if (prodDomain === origin) {
    optionValue = 'SharpHome';
  }

  return optionValue;
};

/**
 * Update the lang param in the url.
 * @param url string
 * @param newLang string
 * @returns string
 */
function replaceLangParam(url: string, newLang: string): string {
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  if (searchParams.has('lang')) {
    searchParams.set('lang', newLang);
    urlObj.search = searchParams.toString();
  } else {
    urlObj.searchParams.append('lang', newLang);
  }

  return urlObj.toString();
}

export {
  getEpicUrl,
  getFDIUrl,
  getFHIRAuthCredentials,
  replaceLangParam,
  getEpicAPIUrl,
  getEpicProxyRedirectValue,
};
