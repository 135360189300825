import { useTranslation } from 'react-i18next';

const IconToggleCaret = () => {
  const { t } = useTranslation();

  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.toggleCaret')}</title>
      <path
        d="M13.2375 0L7.5 5.725L1.7625 0L0 1.7625L7.5 9.2625L15 1.7625L13.2375 0Z"
        fill="#3E76A9"
      />
    </svg>
  );
};

export default IconToggleCaret;
