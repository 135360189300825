import './AccountStatusLabel.scss';
import { useTranslation } from 'react-i18next';

interface AccountStatusLabelProps {
  accountStatus: string;
}

const AccountStatusLabel = ({ accountStatus }: AccountStatusLabelProps) => {
  const { t } = useTranslation();

  return (
    <span
      className={`account-status-label -${accountStatus}`}
      data-testid="account-status-label"
    >
      {t('user.' + accountStatus)}
    </span>
  );
};

export default AccountStatusLabel;
