import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getFDIUrl, getFHIRAuthCredentials } from '../helpers/urlUtils';
import { FHIRLaunchTokenPayload } from '../interfaces/FHIRLaunchTokenJWT';

/**
 * Request a Launch Token.
 */
const launchFHIRLaunchToken = () => {
  window.location.href = getFDIUrl();
};

/**
 * Request an Authorization Code.
 * @param launch string
 * @param iss string
 */
const launchFHIRAuthorization = (launch: string, iss: string) => {
  const { FHIR_URL, CLIENT_ID } = getFHIRAuthCredentials();
  const REDIRECT_URL = window.location.origin + '/';
  const decoded = decodeJWTToken(launch);

  const queryParams = new URLSearchParams({
    response_type: 'code',
    scope: 'launch',
    launch: decoded['epic.metadata'],
    redirect_uri: REDIRECT_URL,
    client_id: CLIENT_ID as string,
    aud: `${FHIR_URL}api/fhir/dstu2`,
  }).toString();

  window.location.href = `${FHIR_URL}oauth2/authorize?${queryParams}`;
};

/**
 * Exchanges the Authorization Code for an Access Token.
 * @param code string
 * @returns any
 */
const requestFHIRAccessToken = async (code: string) => {
  const { FHIR_URL, CLIENT_ID } = getFHIRAuthCredentials();
  const REDIRECT_URL = window.location.origin + '/';
  try {
    const params = new URLSearchParams({
      grant_type: 'authorization_code',
      redirect_uri: REDIRECT_URL,
      code: code,
      client_id: CLIENT_ID as string,
    }).toString();

    const config = {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await axios.post(
      `${FHIR_URL}oauth2/token`,
      params,
      config,
    );

    if (!isValidJWTToken(response.data.access_token)) {
      return null;
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const isValidJWTToken = (token: string) => {
  // Verify the JWT token
  const decoded = decodeJWTToken(token);
  if (!decoded.exp) {
    return true;
  }
  const now = Date.now() / 1000;
  return decoded.exp > now;
};

const decodeJWTToken = (token: string) => {
  return jwtDecode<FHIRLaunchTokenPayload>(token);
};

export {
  launchFHIRLaunchToken,
  launchFHIRAuthorization,
  requestFHIRAccessToken,
  isValidJWTToken,
  decodeJWTToken,
};
