import './PageNotFound.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ShpLogo from '../../components/ShpLogo/ShpLogo';

type Props = {};

const PageNotFound = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="container page-not-found">
      <header>
        <ShpLogo />
        <h1>{t('errors.404.name')}</h1>
      </header>
      <h2>{t('errors.404.title')}</h2>
      <p>{t('errors.404.message')}</p>
      <Link to="/" className="button">
        {t('errors.404.linkText')}
      </Link>
    </div>
  );
};

export default PageNotFound;
