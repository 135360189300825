import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface WebPortalState {
  isModalOpen: boolean;
  isMobileMenuOpen: boolean;
  isAccountDropdownOpen: boolean;
  isMobileAccountDropdownOpen: boolean;
  postLoginRedirect: string | undefined;
}

type WebPortalAction = {
  setIsModalOpen: (modal: boolean) => void;
  setIsMobileMenuOpen: (modal: boolean) => void;
  setIsAccountDropdownOpen: (modal: boolean) => void;
  setIsMobileAccountDropdownOpen: (modal: boolean) => void;
  setPostLoginRedirect: (redirect: string | undefined) => void;
};

export const useWebPortalStore = create<WebPortalState & WebPortalAction>()(
  persist(
    set => ({
      isModalOpen: false,
      setIsModalOpen: (value: boolean) =>
        set(state => ({ isModalOpen: value })),
      isMobileMenuOpen: false,
      setIsMobileMenuOpen: (value: boolean) =>
        set(state => ({ isMobileMenuOpen: value })),
      isAccountDropdownOpen: false,
      setIsAccountDropdownOpen: (value: boolean) =>
        set(state => ({ isAccountDropdownOpen: value })),
      isMobileAccountDropdownOpen: false,
      setIsMobileAccountDropdownOpen: (value: boolean) =>
        set(state => ({ isMobileAccountDropdownOpen: value })),
      postLoginRedirect: undefined,
      setPostLoginRedirect: (value: string | undefined) =>
        set(state => ({ postLoginRedirect: value })),
    }),
    {
      name: 'webPortalStore',
      storage: createJSONStorage(() => sessionStorage),
      partialize: state => ({
        postLoginRedirect: state.postLoginRedirect,
      }),
    },
  ),
);
