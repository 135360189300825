import './AccountForms.scss';
import { useTranslation } from 'react-i18next';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import PersonalUpdates from '../../components/PersonalUpdates/PersonalUpdates';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import { usePharmacyData } from '../../hooks/usePharmacy';
type FormCardProp = {
  label: string;
  link: string;
};

const AccountForms = () => {
  const pharmacy = usePharmacyData();
  const { t } = useTranslation();

  const formCards = [
    {
      label: t('account.forms.allFormsBlock.formCards.claims'),
      link: `/${t('routes.claims.index')}#${t('routes.claims.formsSectionId')}`,
    },
    {
      label: t('account.forms.allFormsBlock.formCards.coverage'),
      link: `/${t('routes.coverage.index')}/${t('routes.coverage.forms')}`,
    },
    {
      label: t('account.forms.allFormsBlock.formCards.care'),
      link: `/${t('routes.findCare.index')}/${t('routes.findCare.forms')}`,
    },
  ];

  if (pharmacy?.hasPharmacy) {
    formCards.push({
      label: t('account.forms.allFormsBlock.formCards.pharmacy'),
      link: `/${t('routes.pharmacy.index')}/${t('routes.pharmacy.forms')}`,
    });
  }

  return (
    <BreadcrumbLayout
      pageTitle={`${t('account.forms.title')}`}
      className="account-forms"
    >
      <>
        <div className="container account-forms__cards">
          <PersonalUpdates hasHeading={false} />
        </div>
        <div>
          <div className="container account-forms__forms">
            <h2 className="account-forms__forms-heading">
              {t('account.forms.allFormsBlock.title')}
            </h2>

            {formCards.map((form: FormCardProp, index) => (
              <ResourceLink to={form.link} text={form.label} key={index} />
            ))}
          </div>
        </div>
      </>
    </BreadcrumbLayout>
  );
};

export default AccountForms;
