import './CardSecondary.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { CardSecondaryProps } from '../../interfaces/CardSecondary';

const CardSecondary: React.FC<CardSecondaryProps> = ({
  title,
  content,
  phone,
  link,
  linkText,
  openInNewTab = false,
  hasHeader = true,
}) => {
  const linkProps = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : '';
  let modifier;

  if (!hasHeader) {
    modifier = `-no-header`;
  }

  return (
    <Link to={link} className={`card ${modifier}`} {...linkProps}>
      <div className="card__header">
        <h3 className="card__title">{title}</h3>
      </div>
      <div className="card__content">
        <p>
          <span dangerouslySetInnerHTML={{ __html: content }} />
          {phone && (
            <>
              <br />
              {phone}
            </>
          )}
        </p>
      </div>
      <div className="card__footer">
        <span className="card__link">{linkText}</span>
      </div>
    </Link>
  );
};

export default CardSecondary;
