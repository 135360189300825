import api from './createApi';

export const getAllPages = async (locale: string) => {
  return await api
    .get('pages', { params: { sf_culture: locale } })
    .then(res => res.data.value);
};

export const getPage = async (url: string, locale: string) => {
  return await api
    .get<any>(`pages/Default.Model(url='${url}')`, {
      params: { sf_culture: locale },
    })
    .then(res => res.data.ComponentContext.Components);
};
