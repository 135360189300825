import './LinkList.scss';
import { Link } from 'react-router-dom';
import IconOpenNewTab from '../IconOpenNewTab/IconOpenNewTab';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';

type Props = {
  links: Array<ResourceLinkProps>;
  columns: number;
};

export default function LinkList({ links, columns }: Props) {
  return (
    <>
      {Array.isArray(links) && links.length > 0 && (
        <ul
          className="links-list"
          data-rowcount={Math.ceil(links.length / 2)}
          data-columns={columns}
        >
          {links.map((link, index) => (
            <li className="links-list__item" key={index}>
              <Link
                to={link.to}
                className="links-list__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.text}
                {link.openInNewTab && <IconOpenNewTab />}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
