import { useTranslation } from 'react-i18next';

const IconMenu = () => {
  const { t } = useTranslation();
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('dashboardNav.openMenu')}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33333 2.5C0.6 2.5 0 1.9375 0 1.25C0 0.5625 0.6 0 1.33333 0H22.6667C23.4 0 24 0.5625 24 1.25C24 1.9375 23.4 2.5 22.6667 2.5H1.33333ZM1.33333 11H22.6667C23.4 11 24 10.4375 24 9.75C24 9.0625 23.4 8.5 22.6667 8.5H1.33333C0.6 8.5 0 9.0625 0 9.75C0 10.4375 0.6 11 1.33333 11ZM1.33333 19.5H22.6667C23.4 19.5 24 18.9375 24 18.25C24 17.5625 23.4 17 22.6667 17H1.33333C0.6 17 0 17.5625 0 18.25C0 18.9375 0.6 19.5 1.33333 19.5Z"
        fill="#00548E"
      />
    </svg>
  );
};

export default IconMenu;
