import { FC } from 'react';
import './PremiumCTA.scss';
import { useTranslation } from 'react-i18next';

import useEpicApiStore from '../../store/useEpicApiStore';
import { Helper } from '@shp/shp-epic';
import LinkPrimary from '../LinkPrimary/LinkPrimary';
import useBillingStatus from '../../hooks/useBillingStatus';

interface PremiumCTAProps {}

const PremiumCTA: FC<PremiumCTAProps> = () => {
  const { t } = useTranslation();
  const { premium } = useEpicApiStore();

  const amountDue = Helper.formatCurrency(premium?.amountDue ?? 0, true);
  const currentInvoice = premium?.invoices
    ? premium.invoices[premium.invoices.length - 1]
    : null;

  const isPastDue = useBillingStatus({
    dueDate: currentInvoice?.dueDate || '',
  });

  if (!premium?.hasBilling) {
    return <></>;
  }

  return (
    <div className="premium-cta" data-testid="PremiumCTA">
      <div className="premium-cta__row">
        <div className="premium-cta__left-column">
          <h2 className="premium-cta__title">{t('home.premiumCta.title')}</h2>
          <p className="premium-cta__text">
            {t('home.premiumCta.description')}
          </p>
        </div>
        <div className="premium-cta__right-column">
          <div className="premium-cta__button">
            <LinkPrimary
              to={`/${t('routes.billing.index')}`}
              text={t('home.premiumCta.pay')}
            />
          </div>
        </div>
      </div>
      <hr className="premium-cta__line" />
      <div className="premium-cta__row-footer">
        <div className="premium-cta__left-column">
          <p className="premium-cta__dueamt">
            {t('home.premiumCta.amountDue')}: <>{amountDue}</>
          </p>
        </div>
        {currentInvoice?.dueDate && (
          <div className="premium-cta__right-column">
            <p className="premium-cta__duedate">
              {isPastDue
                ? t('home.premiumCta.pastDue')
                : `${t('home.premiumCta.due')} ${
                    currentInvoice?.dueDate || ''
                  }`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PremiumCTA;
