import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../../store/useEpicApiStore';
import './SpendingBenefits.scss';
import { Accumulator, CoverageInfo, Helper } from '@shp/shp-epic';
import { TranslatedPlanSpending } from '../../interfaces/TranslatedPlanSpending';
import { TranslatedAccumulator } from '../../interfaces/TranslatedAccumulator';

interface SpendingBenefitsElementProps {
  title: string;
  accumulator: TranslatedAccumulator;
  hasFamilyAccumulators: boolean | undefined;
}

interface ProgressBarProps {
  percentFilled: number;
}

interface SpendingBenefitsProps {}

const SpendingBenefits: FC<SpendingBenefitsProps> = () => {
  const { t } = useTranslation();
  const { planSpending } = useEpicApiStore();

  const keyMap = new Map<keyof CoverageInfo, string>([
    ['individualMoopAccumulated', 'home.coverage.planSpending.individualMoop'],
    ['accountMoopAccumulated', 'home.coverage.planSpending.familyMoop'],
    [
      'individualDeductibleAccumulated',
      'home.coverage.planSpending.individualDeductible',
    ],
    [
      'accountDeductibleAccumulated',
      'home.coverage.planSpending.familyDeductible',
    ],
    [
      'individualRxDeductibleAccumulated',
      'home.coverage.planSpending.individualRxDeductible',
    ],
    [
      'accountRxDeductibleAccumulated',
      'home.coverage.planSpending.familyRxDeductible',
    ],
  ]);

  const translateAccumulator = (
    accumulator: Accumulator,
  ): TranslatedAccumulator => {
    return {
      translationKey: keyMap.get(accumulator.key) ?? accumulator.key,
      spent: accumulator.spent,
      total: accumulator.total,
    };
  };

  const pharmacyPlanSpending: TranslatedPlanSpending = {
    hasFamilyAccumulators: planSpending?.hasFamilyAccumulators || false,
    hasFamilyRxAccumulators: planSpending?.hasFamilyRxAccumulators || false,
    hasTiers: planSpending?.hasTiers || false,
    untiered:
      planSpending?.untiered
        .filter(accumulator => accumulator.key.toLowerCase().includes('rx'))
        .map(translateAccumulator) || [],
    tier1:
      planSpending?.tier1
        .filter(accumulator => accumulator.key.toLowerCase().includes('rx'))
        .map(translateAccumulator) || [],
    tier2:
      planSpending?.tier2
        .filter(accumulator => accumulator.key.toLowerCase().includes('rx'))
        .map(translateAccumulator) || [],
    tier3:
      planSpending?.tier3
        .filter(accumulator => accumulator.key.toLowerCase().includes('rx'))
        .map(translateAccumulator) || [],
  };

  pharmacyPlanSpending.hasTiers =
    pharmacyPlanSpending.tier1.length > 0 ||
    pharmacyPlanSpending.tier2.length > 0 ||
    pharmacyPlanSpending.tier3.length > 0;

  const elements = pharmacyPlanSpending.untiered.map((accumulator, index) => (
    <SpendingBenefitsElement
      title={t(pharmacyPlanSpending.untiered[index].translationKey)}
      accumulator={accumulator}
      hasFamilyAccumulators={pharmacyPlanSpending.hasFamilyRxAccumulators}
      key={index}
    />
  ));

  return (
    <div className="spending-benefits" data-testid="SpendingBenefits">
      {elements.length > 0 && (
        <div className="spending-benefits__body">
          <h3 className="spending-benefits__title">
            {t('pharmacy.spendingBenefits.title')}
          </h3>
          <div className="spending-benefits__list">{elements}</div>
        </div>
      )}
    </div>
  );
};

const SpendingBenefitsElement: React.FC<
  SpendingBenefitsElementProps
> = props => {
  const { t } = useTranslation();
  const accumulatorStyle = props.hasFamilyAccumulators ? '50' : '100';
  const percentSpent =
    100 * (props.accumulator.spent / props.accumulator.total);
  const remainingAmt = props.accumulator.total - props.accumulator.spent;

  return (
    <div
      className={`accumulator accumulator__width-${accumulatorStyle}`}
      tabIndex={0}
      aria-label={`${props.title}: ${Helper.formatCurrency(
        props.accumulator.spent,
        true,
      )}${' '}
  ${t('home.coverage.planSpending.spent')}${' - '}${Helper.formatCurrency(
    remainingAmt,
    true,
  )}${` `}
  ${t('home.coverage.planSpending.remaining')}`}
    >
      <h4>{props.title}</h4>
      <ProgressBar percentFilled={percentSpent} />
      <p>
        <span>
          {Helper.formatCurrency(props.accumulator.spent, true)}{' '}
          {t('home.coverage.planSpending.spent')}
        </span>
        <span>
          <span className="accumulator__spending__prefix">
            {` `} {t('home.coverage.planSpending.spentOf')} {` `}
          </span>
          {Helper.formatCurrency(remainingAmt, true)}
          <span className="accumulator__spending__suffix">
            {` `}
            {t('home.coverage.planSpending.remaining')}
          </span>
        </span>
      </p>
    </div>
  );
};

const ProgressBar: React.FC<ProgressBarProps> = props => {
  return (
    <div className="progress-bar__container">
      <div
        className="progress-bar__inner-bar"
        style={{ width: props.percentFilled + '%' }}
      />
    </div>
  );
};

export default SpendingBenefits;
