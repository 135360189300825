import { Navigate, useLocation } from 'react-router-dom';
import useLoginStore from '../store/LoginStore';
import { useEffect } from 'react';
import { useWebPortalStore } from '../store/WebPortalStore';

interface Props {
  children: React.ReactNode;
}
const PrivateRoute: React.FC<Props> = ({ children }: any) => {
  const location = useLocation();
  const { setPostLoginRedirect } = useWebPortalStore();
  const { isLoggedIn } = useLoginStore();

  useEffect(() => {
    if (!isLoggedIn && location.pathname !== '/login') {
      setPostLoginRedirect(location.pathname + location.search);
    }
  }, [isLoggedIn, location, setPostLoginRedirect]);

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
