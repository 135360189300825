import './HomeButton.scss';
import { Link } from 'react-router-dom';
import { HomeButtonProps } from '../../interfaces/HomeButton';

const HomeButton = ({
  label,
  icon,
  link,
  openInNewTab = false,
}: HomeButtonProps) => {
  const linkProps = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : '';

  return (
    <Link
      to={link}
      className="home-button"
      data-testid="HomeButton"
      aria-label={label}
      {...linkProps}
    >
      <div
        className="home-button__icon"
        dangerouslySetInnerHTML={{ __html: `<i>${icon}</i>` }}
      ></div>
      <span className="home-button__text">{label}</span>
    </Link>
  );
};

export default HomeButton;
