import MainLayout from '../../layouts/MainLayout/MainLayout';
import PrimaryContainer from '../../components/PrimaryContainer/PrimaryContainer';

type PublicFormPageProps = {
  children: JSX.Element;
};

function PublicFormPage({ children }: PublicFormPageProps) {
  return (
    <div data-testid="public-form-page">
      <MainLayout>
        <PrimaryContainer hasHelpModal={true} hasShpLogo={true}>
          <div style={{ marginTop: '4rem' }}>{children}</div>
        </PrimaryContainer>
      </MainLayout>
    </div>
  );
}

export default PublicFormPage;
