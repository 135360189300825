import { useState, useEffect } from 'react';
import axios from 'axios';
import useLoginStore from '../store/LoginStore';
import { getEpicUrl } from '../helpers/urlUtils';
import useLogout from './useLogout';

export function useLoginCheck() {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const epicBaseUrl = getEpicUrl();
  const endpoint = `${epicBaseUrl}Home/KeepAlive`;
  const interval = 1000 * 30;
  const { isLoggedIn, handleLogin, isLoadingLogin, setIsLoadingLogin } =
    useLoginStore();
  const handleLogout = useLogout();

  const checkStatus = async () => {
    try {
      if (isLoadingLogin) return;
      setIsLoadingLogin(true);
      // Ensure credentials are sent to send cookies.
      axios.defaults.withCredentials = true;
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        const isLoggedInResponse = response.data === 1;
        if (isLoggedInResponse !== isLoggedIn) {
          if (isLoggedInResponse) {
            handleLogin();
          } else {
            handleLogout();
          }
        }
      }
      setIsLoadingLogin(false);
      setHasLoaded(true);
    } catch (error) {
      setIsLoadingLogin(false);
      handleLogout();
    }
  };

  useEffect(() => {
    if (!isLoadingLogin) checkStatus();
    const intervalId = setInterval(() => checkStatus(), interval);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoggedIn, hasLoaded };
}
