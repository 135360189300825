import './ResourceLink.scss';
import IconOpenNewTab from '../IconOpenNewTab/IconOpenNewTab';
import IconRightArrow from '../IconRightArrow/IconRightArrow';
import { Link } from 'react-router-dom';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';
import { AnchorHTMLAttributes } from 'react';

export default function ResourceLink({
  to,
  text,
  openInNewTab = false,
  noRefNoOpener = true,
  excerpt = '',
}: ResourceLinkProps) {
  const linkProps: AnchorHTMLAttributes<HTMLAnchorElement> = {};

  if (openInNewTab) {
    linkProps.target = '_blank';

    if (noRefNoOpener) {
      linkProps.rel = 'noopener noreferrer';
    }
  }
  return (
    <div>
      <Link
        className="resource-link"
        to={to}
        {...linkProps}
        data-testid="resource-link"
      >
        {text}

        {(!openInNewTab && <IconRightArrow />) || <IconOpenNewTab />}
      </Link>
      {excerpt && (
        <div
          className="resource-link__excerpt"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></div>
      )}
    </div>
  );
}
