import React from 'react';
import './SupportCard.scss';
import { useTranslation } from 'react-i18next';
import LinkPrimary from '../LinkPrimary/LinkPrimary';
import LinkSecondary from '../LinkSecondary/LinkSecondary';

interface SupportCardProps {}

const SupportCard: React.FC<SupportCardProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="support-card" data-testid="SupportCard">
      <div className="support-card__content">
        <div className="support-card__inner-wrap">
          <h4 className="support-card__title">{t('home.cta.support.title')}</h4>
          <p className="support-card__text">
            {t('home.cta.support.description')}
          </p>
        </div>
        <div className="support-card__button">
          <LinkPrimary
            text={t('home.cta.support.primaryBtn.text')}
            to={`${t('helpBtnLink')}`}
            openInNewTab={true}
          />
          <LinkSecondary
            to={`/${t('routes.support.index')}`}
            text={t('home.cta.support.secondaryBtn.text')}
          />
        </div>
      </div>
    </div>
  );
};

export default SupportCard;
