import { useState } from 'react';
import './CostSharesList.scss';
import { useTranslation } from 'react-i18next';
import { CostShareElement } from '../CostShares/CostShares';

export type TranslatedCostShare = {
  isCopay: boolean;
  translationKey: string;
  amount: number | undefined;
  amountString: string | undefined;
  deductibleApplies: boolean;
};

type Props = {
  costShares: Array<TranslatedCostShare>;
};

export default function CostSharesList({ costShares }: Props) {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <ul className={`cost-shares__tier-list ${showMore ? '-show-all' : ''}`}>
        {costShares.map((costShare, index) => (
          <CostShareElement
            key={'cost-share-element' + index}
            name={t(costShare.translationKey) || ''}
            amount={costShare.amountString}
            deductibleApplies={costShare.deductibleApplies}
          />
        ))}
      </ul>
      <button
        onClick={() => setShowMore(!showMore)}
        className="cost-shares__see-more"
      >
        {showMore
          ? t('home.coverage.costShares.seeLess')
          : t('home.coverage.costShares.seeMore')}
      </button>
    </>
  );
}
