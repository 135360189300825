import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { setLangText } from '../../helpers/setLangText';
import './PublicLanguageSelector.scss';

const PublicLanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage ?? 'en';

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (lang === 'en') {
      i18n.changeLanguage('es');
    } else if (lang === 'es') {
      i18n.changeLanguage('en');
    }
  };

  return (
    <button
      id="language-selector"
      className="language-selector"
      type="button"
      onClick={e => handleButtonClick(e)}
      data-testid="public-language-selector"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{t('languageSelector')}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10C0 4.48 4.47 0 9.99 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 9.99 20C4.47 20 0 15.52 0 10ZM13.97 6H16.92C15.96 4.35 14.43 3.07 12.59 2.44C13.19 3.55 13.65 4.75 13.97 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2 10C2 10.69 2.1 11.36 2.26 12H5.64C5.56 11.34 5.5 10.68 5.5 10C5.5 9.32 5.56 8.66 5.64 8H2.26C2.1 8.64 2 9.31 2 10ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM3.08 6H6.03C6.35 4.75 6.81 3.55 7.41 2.44C5.57 3.07 4.04 4.34 3.08 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM7.5 10C7.5 10.68 7.57 11.34 7.66 12H12.34C12.43 11.34 12.5 10.68 12.5 10C12.5 9.32 12.43 8.65 12.34 8H7.66C7.57 8.65 7.5 9.32 7.5 10ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.5 10C14.5 10.68 14.44 11.34 14.36 12H17.74C17.9 11.36 18 10.69 18 10C18 9.31 17.9 8.64 17.74 8H14.36C14.44 8.66 14.5 9.32 14.5 10Z"
          fill="#58595b"
        />
      </svg>

      <div className="language-selector__text">
        {setLangText(lang, false, true)}
      </div>
    </button>
  );
};

export default PublicLanguageSelector;
