export const languages = [
  [
    {
      language: 'Español',
      tooltipContent:
        'Si usted, o alguien a quien usted está ayudando, tiene preguntas acerca de Sharp Health Plan, tiene derecho a obtener ayuda e información en su idioma sin costo alguno. Para hablar con un intérprete, llame al 1-800-359-2002.',
      tooltipId: 'my-tooltip-es',
      lang: 'es',
    },
    {
      language: '繁體中文',
      tooltipContent:
        '如果您，或是您正在協助的對象，有關 Sharp Health Plan 代碼及範圍方面有疑問，您有權利免費以您的母語得到幫助和訊息。洽詢一位翻譯員，請撥電話 1-800-359-2002。',
      tooltipId: 'my-tooltip-zh',
      lang: 'zh',
    },
    {
      language: 'Tiếng Việt',
      tooltipContent:
        'Nếu quý vị, hay người mà quý vị đang giúp đỡ, có câu hỏi về Sharp Health Plan, quý vị sẽ có quyền được giúp và có thêm thông tin bằng ngôn ngữ của mình miễn phí. Để nói chuyện với một thông dịch viên, xin gọi 1-800-359-2002.',
      tooltipId: 'my-tooltip-vi',
      lang: 'vi',
    },
    {
      language: 'Tagalog',
      tooltipContent:
        'Kung ikaw, o ang iyong tinutulangan, ay may mga katanungan tungkol sa Sharp Health Plan, may karapatan ka na makakuha ng tulong at impormasyon sa iyong wika ng walang gastos. Upang makausap ang isang tagasalin, tumawag sa 1-800-359-2002.',
      tooltipId: 'my-tooltip-tl',
      lang: 'tl',
    },
  ],
  [
    {
      language: '한국어',
      tooltipContent:
        '만약 귀하 또는 귀하가 돕고 있는 어떤 사람이 Sharp Health Plan에 관해서 질문이 있다면 귀하는 그러한 도움과 정보를 귀하의 언어로 비용 부담없이 얻을 수 있는 권리가 있습니다. 그렇게 통역사와 얘기하기 위해서는 1-800-359-2002로 전화하십시오.',
      tooltipId: 'my-tooltip-ko',
      lang: 'ko',
    },
    {
      language: 'հայերեն',
      tooltipContent:
        'Եթե Դուք կամ Ձեր կողմից օգնություն ստացող անձը հարցեր ունի Sharp Health Plan մասին, Դուք իրավունք ունեք անվճար օգնություն և տեղեկություններ ստանալու Ձեր նախընտրած լեզվով։ Թարգմանչի հետ խոսելու համար զանգահարե՛ք 1-800-359-2002։',
      tooltipId: 'my-tooltip-hy',
      lang: 'hy',
    },
    {
      language: 'فارسی',
      tooltipContent:
        'اگر شما، يا کسی کە شما بە او کمک ميکنيد ، سوال در مورد Sharp Health Plan ، داشتە باشيد حق اين را داريد کە کمک و اطلاعات بە زبان خود را بە طور رايگان دريافت نماييد 1.800.359.2002. تماس حاصل نماييد .',
      tooltipId: 'my-tooltip-fa',
      lang: 'fa',
    },
    {
      language: 'русский',
      tooltipContent:
        'Если у вас или лица, которому вы помогаете, имеются вопросы по поводу Sharp Health Plan, то вы имеете право на бесплатное получение помощи и информации на вашем языке. Для разговора с переводчиком позвоните по телефону 1-800-359-2002.',
      tooltipId: 'my-tooltip-ru',
      lang: 'ru',
    },
  ],
  [
    {
      language: '日本語',
      tooltipContent:
        'ご本人様、またはお客様の身の回りの方でも、Sharp Health Planについてご質問がございましたら、ご希望の言語でサポートを受けたり、情報を入手したりすることができます。料金はかかりません。通訳とお話される場合、1-800-359-2002までお電話ください。',
      tooltipId: 'my-tooltip-ja',
      lang: 'ja',
    },

    {
      language: 'العربية',
      tooltipContent:
        'إن كان لديك أو لدى شخص تساعده أسئلة بخصوص Sharp Health Plan ، فلديك الحق في الحصول على المساعدة والمعلومات الضرورية بلغتك من دون اية تكلفة. للتحدث مع مترجم اتصل ب ) 1.800.359.2002.',
      tooltipId: 'my-tooltip-ar',
      lang: 'ar',
    },

    {
      language: 'ਪੰਜਾਬੀ',
      tooltipContent:
        "ਜੇ ਤੁਹਾਨੂੰ , ਜਾਂ ਤੁਸੀ ਜਿਸ ਦੀ ਮਦਦ ਕਰ ਰਹੇ ਹੋ , Sharp Health Plan ਕੋਈ ਸਵਾਲ ਹੈ ਤਾਂ, ਤੁਹਾਨੂੰ ਬਿਨਾ ਕਿਸੇ ਕੀਮਤ 'ਤੇ ਆਪਣੀ ਭਾਸ਼ਾ ਵਿੱਚ ਮਦਦ ਅਤੇ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਕਰਨ ਦਾ ਅਧਿਕਾਰ ਹੈ . ਦੁਭਾਸ਼ੀਏ ਨਾਲ ਗੱਲ ਕਰਨ ਲਈ, 1-800-359-2002 ਤੇ ਕਾਲ ਕਰੋ .",
      tooltipId: 'my-tooltip-pa',
      lang: 'pa',
    },

    {
      language: 'មនខ្មែរ កម្ពុជា',
      tooltipContent:
        'ប្រសិនបរើអ្នក ឬនរណាម្ននក់ដែលអ្នកកំពុងដែជួយ ម្ននសំណួរអ្ំពី Sharp Health Plan បេ, អ្នកម្ននសិេធិេេួលជំនួយនិងព័ែ៌ម្នន បៅកនុងភាសា ររស់អ្នក បោយមិនអ្ស់ប្ាក់ ។ បែើមបីនិយាយជាមួយអ្នករកដប្រ សូម 1-800-359-2002 ។',
      tooltipId: 'my-tooltip-km',
      lang: 'km',
    },
  ],
  [
    {
      language: 'Hmong',
      tooltipContent:
        'Yog koj, los yog tej tus neeg uas koj pab ntawd, muaj lus nug txog Sharp Health Plan, koj muaj cai kom lawv muab cov ntshiab lus qhia uas tau muab sau ua koj hom lus pub dawb rau koj. Yog koj xav nrog ib tug neeg txhais lus tham, hu rau 1-800-359-2002.',
      tooltipId: 'my-tooltip',
      lang: 'hmong',
    },
    {
      language: 'हिंदी',
      tooltipContent:
        'यदि आपके, या आप द्वारा सहायता किए जा रहे किसी व्यक्ति के Sharp Health Plan के बारे में प्रश्न हैं, तो आपके पास अपनी भाषा में मुफ्त में सहायता और सूचना प्राप्त करने का अधिकार है। किसी दुभाषिए से बात करने के लिए ,1-800-359-2002 पर कॉल करें।',
      tooltipId: 'my-tooltip-hi',
      lang: 'hi',
    },
    {
      language: 'ไทย',
      tooltipContent:
        'หากคุณ หรือคนที่คุณกาลังช่วยเหลือมีคาถามเกี่ยวกับ Sharp Health Plan คุณมีสิทธิที่จะได้รับความช่วยเหลือและข้อมูลในภาษาของคุณได้โดยไม่มีค่าใช้จ่าย พูดคุยกับล่าม โทร 1-800-359-2002',
      tooltipId: 'my-tooltip-th',
      lang: 'th',
    },
  ],
];
