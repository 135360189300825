import { useTranslation } from 'react-i18next';

const IconPharmacyRX = () => {
  const { t } = useTranslation();

  return (
    <svg width="37" height="44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{t('icon.pharmacyClaims')}</title>
      <path
        d="M33.675 1.608c.683 0 1.25.559 1.25 1.25V41.2c0 .683-.558 1.25-1.25 1.25H3.058c-.683 0-1.25-.558-1.25-1.25V2.858c0-.683.559-1.25 1.25-1.25h30.617Zm0-1.25H3.058a2.502 2.502 0 0 0-2.5 2.5V41.2c0 1.375 1.125 2.5 2.5 2.5h30.617c1.375 0 2.5-1.117 2.5-2.5V2.858c0-1.375-1.125-2.5-2.5-2.5Z"
        fill="#00548E"
      />
      <path
        d="M5.583 20.742h25.925a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625H5.583a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM5.583 24.483h17.809a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625H5.583a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM20.267 7h11.241a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625H20.267a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM20.267 11.058h7.5a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625h-7.5a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM5.583 27.917h22.492a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625H5.583a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM5.583 31.667h9.684a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625H5.583a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM18.708 31.667h9.375a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625h-9.375a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM5.583 35.725h9.684a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625H5.583a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM18.708 35.725h9.375a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625h-9.375a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM5.583 38.85h9.684a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625H5.583a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM18.708 38.85h9.375a.63.63 0 0 0 .625-.625.63.63 0 0 0-.625-.625h-9.375a.63.63 0 0 0-.625.625.63.63 0 0 0 .625.625ZM9.833 15.548c.025.05.075.159.134.159h1.066c.05 0 .092-.1.117-.134l1.033-1.5 1.034 1.517s.075.117.125.117H14.4c.058 0 .108-.109.133-.159.025-.05.025-.15-.008-.191L12.9 13.04l1.55-2.208c.033-.05.042-.126.008-.176-.033-.05-.075-.1-.133-.1h-1.083c-.05 0-.092.034-.117.076l-.95 1.358-1.858-2.625c.3-.117.575-.284.775-.517a1.72 1.72 0 0 0 .391-1.125v-.017c0-.491-.183-.916-.483-1.225v.05c-.375-.358-.933-.516-1.6-.516H7.108s-.058-.034-.083 0C7 6.04 7 6.03 7 6.073v5.2s0 .109.025.133c.025.025.05.075.083.075h.9s.092-.041.125-.075a.244.244 0 0 0 .059-.133V9.656h.866l2.4 3.384-1.616 2.333a.204.204 0 0 0-.009.184m.45-7.792v.016c0 .225-.108.367-.258.492-.15.125-.4.175-.7.175H8.183V6.931h1.134a1.2 1.2 0 0 1 .716.25c.142.117.25.342.25.584Z"
        fill="#00548E"
      />
    </svg>
  );
};

export default IconPharmacyRX;
