import './ProxyInvite.scss';
import React from 'react';
import EpicIframe from '../../components/EpicIframe/EpicIframe';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const ProxyInvite: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const baseProxyInviteUrl = 'ProxyInvite/Confirmation/Confirm';
  const params = new URLSearchParams(location.search);
  const defaultParams = {
    liteMode: 'true',
    skipAbandonCurrent: '1',
    hideText: '1',
  };

  Object.entries(defaultParams).forEach(([key, value]) => {
    if (!params.has(key)) params.append(key, value);
  });

  const proxyInviteUrl = `${baseProxyInviteUrl}?${params.toString()}`;

  return (
    <BreadcrumbLayout
      pageTitle={`${t('proxyInvite.title')}`}
      className="proxy-invite-page"
    >
      <div className="container" data-testid="proxy-invite">
        <EpicIframe
          title={`${t('proxyInvite.title')}`}
          url={proxyInviteUrl}
          height={1600}
          width={960}
        />
      </div>
    </BreadcrumbLayout>
  );
};

export default ProxyInvite;
