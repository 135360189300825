import './Login.scss';
import LoginWidget from '../../components/LoginWidget/LoginWidget';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { Navigate } from 'react-router-dom';
import useLoginStore from '../../store/LoginStore';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const { t } = useTranslation();
  const { isLoggedIn } = useLoginStore();

  if (isLoggedIn) {
    return <Navigate to={`/${t('routes.home')}`} replace />;
  }

  return (
    <MainLayout>
      <div className="login-page" data-testid="login-page">
        <div className="login-page__form">
          <LoginWidget />
        </div>
      </div>
    </MainLayout>
  );
}
