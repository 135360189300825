import { useEffect } from 'react';

function useHandleExternalLinks(
  hasMedicare: boolean | undefined,
  showModalCallback: (url: string) => void,
) {
  useEffect(() => {
    if (!hasMedicare) return;

    const anchors =
      document.querySelectorAll<HTMLAnchorElement>('a[target="_blank"]');

    const handleClick = (e: MouseEvent) => {
      e.preventDefault();
      const target = e.currentTarget as HTMLAnchorElement;
      showModalCallback(target.href);
    };

    anchors.forEach(anchor => {
      anchor.addEventListener('click', handleClick);
    });

    return () => {
      anchors.forEach(anchor => {
        anchor.removeEventListener('click', handleClick);
      });
    };
  }, [hasMedicare, showModalCallback]);
}

export default useHandleExternalLinks;
