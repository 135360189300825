import './BreadcrumbLayout.scss';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

type Props = {
  children: JSX.Element;
  pageTitle: string;
  className?: string;
  theme?: string;
  setTitleFormat?: boolean;
};

const convertToTitleFormat = (str: string) => {
  const stringWithSpaces = str.replace(/-/g, ' ');
  const sentenceCase =
    stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
  return sentenceCase;
};

const BreadcrumbLayout = ({
  children,
  pageTitle,
  className,
  theme,
  setTitleFormat = true,
}: Props) => {
  const formattedPageTitle = setTitleFormat
    ? convertToTitleFormat(pageTitle)
    : pageTitle;
  let themeModifier;

  if ('large' === theme) {
    themeModifier = `-${theme}`;
  }

  return (
    <div className={`breadcrumb-page ${className} ${themeModifier}`}>
      <div className="breadcrumb-page__header">
        <Breadcrumbs />
        <h1 className="breadcrumb-page__title">{formattedPageTitle}</h1>
      </div>
      <div className="breadcrumb-page__content">{children}</div>
    </div>
  );
};

export default BreadcrumbLayout;
