import './Messages.scss';
import EpicIframe from '../../components/EpicIframe/EpicIframe';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import { useSearchParams } from 'react-router-dom';

const Messages = () => {
  const [searchParams] = useSearchParams();
  const askQuestions =
    searchParams.get('ask-question') === 'true' ? 'ask-question' : '';

  return (
    <BreadcrumbLayout pageTitle="Messages" className="messages" theme="large">
      <>
        <div className="container-large messages">
          <EpicIframe
            title="Messages"
            url={`app/communication-center/${askQuestions}`}
            width={500}
            height={690}
          />
        </div>
      </>
    </BreadcrumbLayout>
  );
};

export default Messages;
