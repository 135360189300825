function setLangText(
  locale: string,
  isAbbreviated?: boolean,
  returnInactive?: boolean,
): string {
  let text = 'English';
  let inactive = 'Español';

  if (locale === 'es') {
    text = 'Español';
    inactive = 'English';
  }

  if (returnInactive) {
    text = inactive;
  }

  return isAbbreviated ? text.substring(0, 3) : text;
}

export { setLangText };
