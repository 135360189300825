import CardTertiary, { CardContent } from '../CardTertiary/CardTertiary';
import TwoColumnCardGrid from '../TwoColumnCardGrid/TwoColumnCardGrid';
import './PPOFindCareTiers.scss';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';
import LinkList from '../LinkList/LinkList';
import SearchCareLinks from '../SearchCareLinks/SearchCareLinks';
import { useTranslation } from 'react-i18next';
import { useSupplementCare } from '../../hooks/useSupplementalCare';

export default function PPOFindCareTiers() {
  const { t } = useTranslation();
  const ppoTier2Links: ResourceLinkProps[] = t('findCare.ppo.tier2.links', {
    returnObjects: true,
  });

  type SupplementLink = {
    text: string;
    to: string;
  };
  const ppoSupplementalLinks: ResourceLinkProps[] = [];

  const supplementalCare = useSupplementCare(true);
  if (supplementalCare) {
    supplementalCare.forEach((item: SupplementLink) => {
      ppoSupplementalLinks.push({
        to: item.to,
        text: item.text,
        openInNewTab: true,
      });
    });
  }

  const ppoTierContent: Array<CardContent> = [
    {
      header: (
        <h3 className="ppo-tier-card__header" key="ppo-tier1-header">
          <b>{t('findCare.ppo.tier1.header.prefix')}</b>{' '}
          {t('findCare.ppo.tier1.header.suffix')}
        </h3>
      ),
      body: [
        <div className="ppo-tier-card__body" key="ppo-tier1-body">
          <p>{t('findCare.ppo.tier1.body')}</p>
          <SearchCareLinks />
        </div>,
      ],
    },
    {
      header: (
        <h3 className="ppo-tier-card__header half-width" key="ppo-tier2-header">
          <b>{t('findCare.ppo.tier2.header.prefix')}</b>{' '}
          {t('findCare.ppo.tier2.header.suffix')}
        </h3>
      ),
      body: [
        <div className="ppo-tier-card__body half-width" key="ppo-tier2-body">
          <p>{t('findCare.ppo.tier2.body')}</p>
          <LinkList links={ppoTier2Links} columns={1} />
        </div>,
      ],
    },
    {
      header: (
        <h3 className="ppo-tier-card__header half-width" key="ppo-tier3-header">
          <b>{t('findCare.ppo.tier3.header.prefix')}</b>{' '}
          {t('findCare.ppo.tier3.header.suffix')}
        </h3>
      ),
      body: [
        <div key="ppo-tier3-body" className="ppo-tier-card__body half-width">
          <p
            dangerouslySetInnerHTML={{
              __html: t('findCare.ppo.tier3.body') as string,
            }}
          ></p>
        </div>,
      ],
    },
    {
      header: (
        <h3
          className="ppo-tier-card__header blue-bg"
          key="ppo-supplemental-header"
        >
          {t('findCare.ppo.supplementalBenefits.header')}
        </h3>
      ),
      body: [
        <div className="ppo-tier-card__body" key="ppo-supplemental-body">
          <p>{t('findCare.ppo.supplementalBenefits.body')}</p>
          <LinkList links={ppoSupplementalLinks} columns={2} />
        </div>,
      ],
    },
  ];

  return (
    <div className="ppo-find-care-tiers">
      <CardTertiary content={ppoTierContent[0]} key="ppo-find-care-tier-1" />
      <TwoColumnCardGrid>
        <CardTertiary content={ppoTierContent[1]} key="ppo-find-care-tier-2" />
        <CardTertiary content={ppoTierContent[2]} key="ppo-find-care-tier-3" />
      </TwoColumnCardGrid>
      {ppoSupplementalLinks.length > 0 && (
        <CardTertiary
          content={ppoTierContent[3]}
          key="ppo-find-care-supplemental"
        />
      )}
    </div>
  );
}
