import { useTranslation } from 'react-i18next';

const IconBook = () => {
  const { t } = useTranslation();

  return (
    <svg
      width="58"
      height="50"
      viewBox="0 0 58 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.book')}</title>
      <g clipPath="url(#clip0_10_1236)">
        <path
          d="M55.5748 7.13808H51.9456V2.37379C51.9456 1.72183 51.6912 1.12003 51.2333 0.685389C50.7754 0.234035 50.1819 0 49.5205 0C36.5637 0.234035 31.069 4.1792 29 6.36911C26.931 4.1792 21.4363 0.234035 8.47953 0C7.83509 0 7.22456 0.234035 6.76667 0.668673C6.30877 1.12003 6.05439 1.72183 6.05439 2.35707V7.12136H2.42515C1.08538 7.12136 0 8.19124 0 9.51187V47.6262C0 48.9468 1.08538 50.0167 2.42515 50.0167H55.5918C56.9316 50.0167 58.017 48.9468 58.017 47.6262V9.51187C58.017 8.19124 56.9316 7.12136 55.5918 7.12136M30.221 8.65931C30.9842 7.58943 35.2748 2.62454 49.5544 2.35707V40.4881C38.5649 40.6887 32.9515 43.5473 30.221 45.7372V8.65931ZM27.7959 8.65931V45.7372C25.0655 43.5306 19.452 40.672 8.46257 40.4714V2.37379C22.776 2.62454 27.0497 7.60615 27.7959 8.65931ZM2.42515 9.51187H6.03743V40.4881C6.03743 41.792 7.10585 42.8619 8.42865 42.8786C18.8924 43.0625 23.9971 45.7706 26.2865 47.6262H2.42515V9.51187ZM55.5918 47.6262H31.7134C34.0029 45.7706 39.1076 43.0625 49.5713 42.8786C50.8941 42.8619 51.9626 41.7753 51.9626 40.4881V9.51187H55.5918V47.6262Z"
          fill="#00548E"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_1236">
          <rect width="58" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconBook;
