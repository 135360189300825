import { FC, ReactNode } from 'react';
import './ThreeColumnCards.scss';

interface ThreeColumnCardsProps {
  children: ReactNode;
}

const ThreeColumnCards: FC<ThreeColumnCardsProps> = ({ children }) => {
  return <div className="three-column-cards">{children}</div>;
};

export default ThreeColumnCards;
