import { Link } from 'react-router-dom';

type Props = {
  text: string;
  to: string;
  openInNewTab?: boolean;
};

const LinkPrimary = ({ text, to, openInNewTab = false }: Props) => {
  const linkProps = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : '';
  return (
    <Link to={to} className="button" {...linkProps} data-testid="link-primary">
      {text}
    </Link>
  );
};

export default LinkPrimary;
