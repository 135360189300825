import './DashboardHeader.scss';
import { MouseEventHandler, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useWebPortalStore } from '../../store/WebPortalStore';
import IconMail from '../IconMail/IconMail';
import IconProfile from '../IconProfile/IconProfile';
import Navigation from '../Navigation/Navigation';
import NavigationMobile from '../NavigationMobile/NavigationMobile';
import ShpLogo from '../ShpLogo/ShpLogo';
import IconMenu from '../IconMenu/IconMenu';
import AccountDropdownMenu from '../AccountDropdownMenu/AccountDropdownMenu';
import IconNotifications from '../ShpIcons/IconNotifications';

import IconProfileCaret from '../IconProfile/IconProfileCaret';

const DashboardHeader = () => {
  const { t } = useTranslation();
  const header = useRef<HTMLHeadingElement>(null);
  const accountMenu = useRef<HTMLDivElement>(null);
  const {
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    isAccountDropdownOpen,
    setIsAccountDropdownOpen,
    isMobileAccountDropdownOpen,
  } = useWebPortalStore();

  const handleMenuClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();

    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleAccountClick: MouseEventHandler<HTMLButtonElement> = event => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen);
  };

  useEffect(() => {
    const headerEl = header.current;
    const accountMenuEl = accountMenu.current;

    if (!headerEl || !accountMenuEl) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (header.current && !header.current.contains(event.target as Node)) {
        setIsMobileMenuOpen(false);
      }
    };

    const handleAccountClickOutside = (event: MouseEvent) => {
      if (
        accountMenu.current &&
        !accountMenu.current.contains(event.target as Node)
      ) {
        setIsAccountDropdownOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);
    window.addEventListener('click', handleAccountClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('click', handleAccountClickOutside);
    };
  }, [header, setIsMobileMenuOpen, accountMenu, setIsAccountDropdownOpen]);

  return (
    <header
      className={`dashboard-header ${isMobileMenuOpen ? '-is-mobile-open' : ''}
        ${
          isMobileMenuOpen && isMobileAccountDropdownOpen
            ? '-is-mobile-account-open'
            : ''
        }
      `}
      ref={header}
      data-testid="dashboard-header"
    >
      <div className="dashboard-header__top">
        <div className="dashboard-header__left">
          <Link to="/">
            <ShpLogo />
          </Link>
        </div>

        <div className="dashboard-header__right -mobile">
          <button
            className="dashboard-header__action"
            onClick={e => handleMenuClick(e)}
            aria-haspopup="true"
            aria-controls="mobileMenu"
            aria-expanded={isMobileMenuOpen}
          >
            <IconMenu />
          </button>
        </div>

        <div className="dashboard-header__right -desktop">
          <Link
            to={`${t('routes.notifications')}`}
            className="dashboard-header__action"
          >
            <IconNotifications />
          </Link>
          <Link
            to={`${t('routes.messages')}`}
            className="dashboard-header__action"
          >
            <IconMail />
          </Link>
          <div className="dashboard-header__account" ref={accountMenu}>
            <button
              className="dashboard-header__action"
              onClick={e => handleAccountClick(e)}
              aria-haspopup="true"
              aria-controls="account-dropdown"
              aria-expanded={isAccountDropdownOpen}
            >
              <IconProfile />
              <IconProfileCaret />
            </button>
            {isAccountDropdownOpen && <AccountDropdownMenu />}
          </div>
        </div>
      </div>

      {isMobileMenuOpen && <NavigationMobile />}
      <Navigation />
    </header>
  );
};

export default DashboardHeader;
