import './FindCare.scss';
import { useTranslation } from 'react-i18next';
import { PrimaryIconLinkProps } from '../../interfaces/PrimaryIconLink';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import SearchCareBlock from '../../components/SearchCareBlock/SearchCareBlock';
import AdditionalCareBlock from '../../components/AdditionalCareBlock/AdditionalCareBlock';
import CareTeamBlock from '../../components/CareTeamBlock/CareTeamBlock';
import PrimaryIconLink from '../../components/PrimaryIconLink/PrimaryIconLink';
import ManageCareBlock from '../../components/ManageCareBlock/ManageCareBlock';
import IconBook from '../../components/ShpIcons/IconBook';
import { Link } from 'react-router-dom';
import IconRightArrow from '../../components/IconRightArrow/IconRightArrow';
import useEpicApiStore from '../../store/useEpicApiStore';
import PPOFindCareTiers from '../../components/PPOFindCareTiers/PPOFindCareTiers';

const FindCare = () => {
  const { t } = useTranslation();
  const { eduAssigned, isPPO } = useEpicApiStore();

  const ctaBlock: PrimaryIconLinkProps = t('findCare.ctaBlock', {
    returnObjects: true,
  });

  const ppoSearchForCare: Array<string> = t(
    'findCare.ppoHowToSearchForCare.content',
    {
      returnObjects: true,
    },
  );

  return (
    <BreadcrumbLayout pageTitle={t('findCare.pageName')} className="find-care">
      <>
        {isPPO && (
          <div className="container -ppo-search-for-care">
            <h2>{t('findCare.ppoHowToSearchForCare.header')}</h2>
            {ppoSearchForCare.map((item, index) => (
              <p
                dangerouslySetInnerHTML={{ __html: item }}
                key={'ppo_tier-one_content-' + index}
              ></p>
            ))}
            <p className="note">{t('findCare.ppoHowToSearchForCare.note')}</p>
          </div>
        )}
        {!isPPO && (
          <div>
            <div className="container">
              <CareTeamBlock />
            </div>
          </div>
        )}
        {isPPO && (
          <div className="container -ppo-tiers">
            <PPOFindCareTiers />
          </div>
        )}
        <div>
          <div className="container -search-care">
            {!isPPO && <SearchCareBlock />}
            <AdditionalCareBlock />
          </div>
        </div>
        <div>
          <div className="container -manage-care">
            <ManageCareBlock />
          </div>
        </div>
        <div>
          <div className="container -health-library">
            <>
              <PrimaryIconLink {...ctaBlock}>
                <IconBook />
              </PrimaryIconLink>
              {eduAssigned && (
                <Link
                  to={`/${t('routes.findCare.index')}/${t(
                    'routes.findCare.healthLibrary',
                  )}`}
                  className="primary-icon-link -health-library-personalized"
                >
                  <span>{t('findCare.personalizedHealth.title')}</span>
                  <IconRightArrow />
                </Link>
              )}
            </>
          </div>
        </div>
      </>
    </BreadcrumbLayout>
  );
};

export default FindCare;
