import { WrapperData } from '../interfaces/WrapperData';
import { Member } from '../interfaces/Epic';
/**
 * Parses the wrapper data from the API into an object.
 * @param input string
 * @returns object
 */
function parseWrapperData(input: string): WrapperData {
  const data: WrapperData = {};

  const rows = input.replace(/^"|"$/g, '').split('|');

  // Maybe this should happen from the shared code?
  const proxyEntries = rows.filter(entry => entry.startsWith('PROXY,,'));

  data.proxyMembers = proxyEntries.map(entry => {
    const parts = entry.split(',');
    return {
      id: undefined,
      eid: parts[3],
      firstName: parts[4].split(' ')[0],
      lastName: parts[4].split(' ')[1],
      name: parts[4],
    } as Member;
  });

  for (const row of rows) {
    const [key, id, value, extraValue] = row.split(',');
    data[key] = isNaN(Number(value)) ? value : Number(value);
    if (extraValue !== undefined) {
      data[`${key}_extra`] = isNaN(Number(extraValue))
        ? extraValue
        : Number(extraValue);
    }
    if (id !== undefined && !isNaN(Number(id)) && data['ID'] === undefined) {
      data['ID'] = Number(id);
    }
  }

  return data;
}

/**
 * Parses a string, number, or Date into a slug.
 * @param input string | number | Date | undefined
 * @returns string
 */
function parseSlug(input: string | number | Date | undefined): string {
  let str = '';
  if (typeof input === 'string') {
    str = input;
  } else if (typeof input === 'number') {
    str = input.toString();
  } else if (input instanceof Date) {
    str = input.toISOString();
  }
  // return a string that is lowercase, space converted to dash, and all non-alphanumeric characters removed
  return str
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^a-z0-9-]/g, '');
}

export { parseWrapperData, parseSlug };
