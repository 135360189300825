import { useTranslation } from 'react-i18next';
const IconMail = () => {
  const { t } = useTranslation();
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('dashboardNav.messages')}</title>
      <path
        d="M26.041 27.1354C25.6224 27.2038 25.2465 27.4003 24.9218 27.6737L21.8462 30.3222C21.419 30.7322 20.7526 30.7493 20.2998 30.3649L17.5915 28.041C16.9764 27.5199 16.1989 27.2294 15.3873 27.2209L5.39137 27.1781C2.9821 27.1781 1 24.6236 1 21.5052V8.67289C1.00854 5.55451 2.98209 3 5.39991 3H25.5028C27.9121 3 29.8942 5.55451 29.8942 8.67289V21.5138C29.8942 24.393 28.2111 26.7937 26.0496 27.1354H26.041Z"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.60414 17.2839C8.88284 17.2839 9.91943 16.2473 9.91943 14.9686C9.91943 13.6899 8.88284 12.6533 7.60414 12.6533C6.32544 12.6533 5.28885 13.6899 5.28885 14.9686C5.28885 16.2473 6.32544 17.2839 7.60414 17.2839Z"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M15.447 17.2839C16.7257 17.2839 17.7623 16.2473 17.7623 14.9686C17.7623 13.6899 16.7257 12.6533 15.447 12.6533C14.1683 12.6533 13.1317 13.6899 13.1317 14.9686C13.1317 16.2473 14.1683 17.2839 15.447 17.2839Z"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <path
        d="M23.2131 17.2839C24.4918 17.2839 25.5284 16.2473 25.5284 14.9686C25.5284 13.6899 24.4918 12.6533 23.2131 12.6533C21.9344 12.6533 20.8978 13.6899 20.8978 14.9686C20.8978 16.2473 21.9344 17.2839 23.2131 17.2839Z"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <circle cx="29.5" cy="4.5" r="4" fill="#C43B4B" stroke="white" />
    </svg>
  );
};

export default IconMail;
