import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useHealthTrio } from './useHealthTrio';
import useEpicApiStore from '../store/useEpicApiStore';

const useCareTeamData = () => {
  const { t } = useTranslation();
  const { networkSearch, medicalGroupSearch, myPrimaryCarePhysicianSearch } =
    useHealthTrio();
  const { networkName, provider } = useEpicApiStore();

  const careTeam = useMemo(() => {
    const team = [];

    if (provider?.name.first && provider?.name.last) {
      team.push({
        title: t('home.careTeamCards.pcp.title'),
        subtitle: `${provider?.name.first} ${provider?.name.last}`,
        subtitle2: provider?.phone || '',
        linkText: t('home.careTeamCards.pcp.linkText'),
        link: myPrimaryCarePhysicianSearch,
        openInNewTab: true,
      });
    }
    if (provider?.pmgName) {
      team.push({
        title: t('home.careTeamCards.pmg.title'),
        subtitle: provider?.pmgName,
        subtitle2: '',
        linkText: t('home.careTeamCards.pmg.linkText'),
        link: medicalGroupSearch,
        openInNewTab: true,
      });
    }

    if (networkName) {
      team.push({
        title: t('home.careTeamCards.findADoc.title'),
        subtitle: networkName,
        subtitle2: '',
        linkText: t('home.careTeamCards.findADoc.linkText'),
        link: networkSearch,
        openInNewTab: true,
      });
    }

    return team;
  }, [
    provider,
    networkName,
    t,
    networkSearch,
    medicalGroupSearch,
    myPrimaryCarePhysicianSearch,
  ]);

  return careTeam;
};

export default useCareTeamData;
