import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

const IconNotifications = (props: Props) => {
  const { t } = useTranslation();

  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.notifications')}</title>
      <path
        d="M24.7538 24.0283C24.7538 24.7451 24.4657 25.3845 24.0019 25.8553C23.5311 26.3191 22.8916 26.6072 22.1748 26.6072H3.7217C2.23898 26.6072 0.938972 25.3916 1.00222 23.9159C1.03032 23.2483 1.31141 22.651 1.75411 22.2083C2.12655 21.8359 2.59736 21.5829 3.13142 21.4845C3.41953 21.4353 3.65846 21.2315 3.75683 20.9504C5.16928 16.7693 5.21847 11.4147 5.21847 10.7401V10.7049C5.21847 7.21248 7.53741 4.07839 10.9245 3.24217C15.998 1.98432 20.5446 5.80003 20.5446 10.6698V10.7612C20.5446 11.506 20.5937 16.7974 21.9921 20.9364C22.0905 21.2315 22.3505 21.4353 22.6597 21.4915C23.8543 21.7164 24.7538 22.7705 24.7538 24.0283Z"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.93555 21.7344H19.9054"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.878 30.4884C13.9297 30.4884 14.7823 29.6358 14.7823 28.584C14.7823 27.5323 13.9297 26.6797 12.878 26.6797C11.8262 26.6797 10.9736 27.5323 10.9736 28.584C10.9736 29.6358 11.8262 30.4884 12.878 30.4884Z"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeMiterlimit="10"
      />
      <circle cx="20.3789" cy="4.5" r="4" fill="#C43B4B" stroke="white" />
    </svg>
  );
};

export default IconNotifications;
