import { BenefitType } from '@shp/shp-epic';
import useEpicApiStore from './../store/useEpicApiStore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SupplementLink = {
  text: string;
  to: string;
  id: string;
  type: string;
};

type BenefitInfo = {
  [benefitType in BenefitType]?: SupplementLink;
};

export function useSupplementCare(forFindCare: boolean = false) {
  const { t } = useTranslation();
  const {
    isCalpers,
    hasMedicare,
    isPOS,
    isPPO,
    coverages,
    supplementalBenefits,
  } = useEpicApiStore();
  const [supplementalCare, setSupplementalCare] = useState<SupplementLink[]>(
    [],
  );

  useEffect(() => {
    const activeCoverage = supplementalBenefits;

    if (activeCoverage) {
      const benefits = activeCoverage.benefits || [];

      const newSupplementalCare = benefits
        .map(benefit => {
          const benefitInfo = getSupplementLinks(
            t,
            hasMedicare,
            isCalpers,
            isPOS || isPPO,
            benefit.id,
            benefit.benefitType,
          )[benefit.benefitType];

          return benefitInfo;
        })
        .filter((item): item is SupplementLink => item !== undefined)
        .filter(
          item =>
            !forFindCare || (forFindCare && item.type !== BenefitType.ART),
        );

      setSupplementalCare(newSupplementalCare);
    }
  }, [
    isCalpers,
    hasMedicare,
    isPOS,
    isPPO,
    coverages,
    t,
    forFindCare,
    supplementalBenefits,
  ]);

  return supplementalCare;
}

const getSupplementLinks = (
  t: (key: string) => string,
  hasMedicare: boolean | undefined,
  isCalpers: boolean | undefined,
  isTiered: boolean | undefined,
  id: string,
  type: string,
): BenefitInfo => {
  const currentYear = new Date().getFullYear();
  let pharmacyLink =
    'https://www.caremark.com/wps/myportal/PHARMACY_LOCATOR_FAST';
  let acupunctureLink = 'https://www.ashlink.com/ash/ashco.aspx?hp=SharpComm';
  let dentalLink =
    'https://www.deltadentalins.com/dentist-directory/deltacare-usa.html';

  if (hasMedicare) {
    pharmacyLink = `https://www.medicareplanrx.com/PharmacyLocator/?contractId=H5386&lang=en&year=${currentYear}&clientId=183`;
    acupunctureLink = 'https://www.ashlink.com/ash/ashco.aspx?hp=SharpHP';
    dentalLink =
      'https://www.deltadentalins.com/dentist-directory/deltacare-usa-medicare.html';
  } else if (isTiered) {
    acupunctureLink = 'https://www.ashlink.com/ash/ashco.aspx?hp=sharpHPpos';
  } else if (isCalpers) {
    pharmacyLink = 'https://www.optumrx.com/oe_calpers/pharmacy-locator';
    acupunctureLink =
      'https://calpers.sharphealthplan.com/basic-plan/get-care/chiropractic-acupuncture';
  }

  const map: BenefitInfo = {
    [BenefitType.ACU_CHIRO]: {
      text: t('supplementalCare.acuChiro'),
      to: acupunctureLink,
      id,
      type,
    },
    [BenefitType.ACUPUNCTURE]: {
      text: t('supplementalCare.acupuncture'),
      to: acupunctureLink,
      id,
      type,
    },
    [BenefitType.CHIROPRACTIC]: {
      text: t('supplementalCare.chiropractic'),
      to: acupunctureLink,
      id,
      type,
    },
    [BenefitType.PEDIATRIC_DENTAL]: {
      text: t('supplementalCare.dental'),
      to: dentalLink,
      id,
      type,
    },
    [BenefitType.VISION]: {
      text: t('supplementalCare.vision'),
      to: 'https://www.vsp.com/eye-doctor',
      id,
      type,
    },
    [BenefitType.PEDIATRIC_VISION]: {
      text: t('supplementalCare.vision'),
      to: 'https://www.vsp.com/eye-doctor',
      id,
      type,
    },
    [BenefitType.HEARING_AID]: {
      text: t('supplementalCare.hearingAid'),
      to: 'https://www.hearusa.com/hearing-centers/',
      id,
      type,
    },
    [BenefitType.SILVER_FIT]: {
      text: t('supplementalCare.silverFit'),
      to: 'https://www.silverandfit.com/',
      id,
      type,
    },
    [BenefitType.PHARMACY]: {
      text: t('supplementalCare.pharmacy'),
      to: pharmacyLink,
      id,
      type,
    },
    [BenefitType.ART]: {
      text: t('supplementalCare.art'),
      to: '#',
      id,
      type,
    },
  };

  return map;
};
