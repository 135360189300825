import './PrimaryIconLink.scss';
import { Link } from 'react-router-dom';
import IconRightArrow from '../IconRightArrow/IconRightArrow';
import IconOpenNewTab from '../IconOpenNewTab/IconOpenNewTab';
import { PrimaryIconLinkProps } from '../../interfaces/PrimaryIconLink';

const PrimaryIconLink = ({
  children,
  to,
  title,
  text,
  openInNewTab = false,
  theme,
}: PrimaryIconLinkProps) => {
  const linkProps = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : '';
  let themeModifier;

  if ('small' === theme) {
    themeModifier = `-${theme}`;
  }

  return (
    <Link
      className={`primary-icon-link ${themeModifier}`}
      to={to}
      {...linkProps}
    >
      <span className="primary-icon-link__content">
        {children}
        <div className="primary-icon-link__text-wrapper">
          <span className="primary-icon-link__title">{title}</span>
          <span className="primary-icon-link__text">{text}</span>
        </div>
      </span>

      <span className="primary-icon-link__indicator">
        {(!openInNewTab && <IconRightArrow />) || <IconOpenNewTab />}
      </span>
    </Link>
  );
};

export default PrimaryIconLink;
