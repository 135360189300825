import './AccordionLinks.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import IconToggleCaret from '../IconToggleCaret/IconToggleCaret';
import IconRightArrow from '../IconRightArrow/IconRightArrow';
import IconOpenNewTab from '../IconOpenNewTab/IconOpenNewTab';

type LinkItem = {
  link: string;
  label: string;
  openInNewTab?: boolean;
};

type Props = {
  title: string;
  items: LinkItem[];
};

const AccordionLinks = ({ title, items }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="accordion-links" data-testid="AccordionLinks">
      <div className="accordion-links__title" onClick={toggleOpen}>
        {title}
        <span className={`accordion-links__icon ${isOpen ? '-open' : ''}`}>
          <IconToggleCaret />
        </span>
      </div>
      {isOpen && (
        <div className="accordion-links__content">
          {items.map(item => {
            const linkProps = item.openInNewTab
              ? {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }
              : {};
            return (
              <Link
                className="accordion-links__link"
                to={item.link}
                key={item.link}
                {...linkProps}
              >
                {item.label}
                {(!item.openInNewTab && <IconRightArrow />) || (
                  <IconOpenNewTab />
                )}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AccordionLinks;
