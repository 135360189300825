import { FC, ReactNode } from 'react';
import './TwoColumnCardGrid.scss';

interface TwoColumnCardGridProps {
  children: ReactNode;
}

const TwoColumnCardGrid: FC<TwoColumnCardGridProps> = ({ children }) => {
  return <div className="two-column-cards">{children}</div>;
};

export default TwoColumnCardGrid;
