import './ExternalLinkModal.scss';
import InformationModal from '../InformationModal/InformationModal';
import { useTranslation } from 'react-i18next';

interface ExternalLinkModalProps {
  clickedExternalUrl: string | null;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExternalLinkModal = ({
  clickedExternalUrl,
  showModal,
  setShowModal,
}: ExternalLinkModalProps) => {
  const { t } = useTranslation();

  return (
    <InformationModal
      title={t('externalLinkModal.title')}
      isOpen={showModal}
      setIsOpen={setShowModal}
    >
      <div className="external-link-modal">
        <p>{t('externalLinkModal.info')}</p>
        <div className="external-link-modal__buttons">
          <button className="button" onClick={() => setShowModal(false)}>
            {t('externalLinkModal.back')}
          </button>
          <button
            className="secondary-button"
            onClick={() => {
              window.open(clickedExternalUrl as string, '_blank');
              setShowModal(false);
            }}
          >
            {t('externalLinkModal.continue')}
          </button>
        </div>
      </div>
    </InformationModal>
  );
};

export default ExternalLinkModal;
