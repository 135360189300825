import './AdditionalCareBlock.scss';
import ThreeColumnCards from '../../components/ThreeColumnCards/ThreeColumnCards';
import CardSecondary from '../../components/CardSecondary/CardSecondary';
import { useTranslation } from 'react-i18next';
import { CardSecondaryProps } from '../../interfaces/CardSecondary';

//  TODO: Fix CardSecondary mobile styles

const AdditionalCareBlock = () => {
  const { t } = useTranslation();

  const cards: CardSecondaryProps[] = t('findCare.additionalCare.cards', {
    returnObjects: true,
  });

  return (
    <div className="additional-care">
      <h2 className="additional-care__heading">
        {t('findCare.additionalCare.title')}
      </h2>

      <ThreeColumnCards>
        {cards.map(card => (
          <CardSecondary key={card.title} {...card} />
        ))}
      </ThreeColumnCards>
    </div>
  );
};

export default AdditionalCareBlock;
