import './LoadingSpinner.scss';

type LoadingSpinnerProps = {
  message?: string;
};

function LoadingSpinner({
  message = 'the page is loading',
}: LoadingSpinnerProps) {
  return (
    <div className="loading-spinner">
      <div className="loading-spinner__wheel">
        <span className="loading-spinner__text" role="alert">
          {message}
        </span>
      </div>
    </div>
  );
}

export default LoadingSpinner;
