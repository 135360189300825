import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useWebPortalStore } from '../../store/WebPortalStore';
import FocusTrap from 'focus-trap-react';
import ShpLogo from '../ShpLogo/ShpLogo';
import Faqs from '../Faqs/Faqs';
import './HelpModal.scss';

const HelpModal = () => {
  const { t } = useTranslation();
  const { isModalOpen, setIsModalOpen } = useWebPortalStore();

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = event => {
    setIsModalOpen(false);
  };

  window.addEventListener('keydown', event => {
    if (event.key === 'Escape') {
      setIsModalOpen(false);
    }
  });

  return (
    <FocusTrap focusTrapOptions={{ fallbackFocus: '#help-modal' }}>
      <div
        id="help-modal"
        className="help-modal"
        aria-hidden={!isModalOpen}
        data-testid="help-modal"
      >
        <div className="help-modal__container">
          <div className="help-modal__content">
            <div className="help-modal__logo">
              <button
                className="help-modal__close"
                onClick={e => handleButtonClick(e)}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>{t('modal.close')}</title>
                  <circle cx="16" cy="16" r="16" fill="#F6F6F6" />
                  <path
                    d="M23 10.41L21.59 9L16 14.59L10.41 9L9 10.41L14.59 16L9 21.59L10.41 23L16 17.41L21.59 23L23 21.59L17.41 16L23 10.41Z"
                    fill="#58595B"
                  />
                </svg>
              </button>
              <Link to="/" reloadDocument={true}>
                <ShpLogo />
              </Link>
            </div>
            <div className="help-modal__info">
              <h2 className="help-modal__info-heading">{t('modal.heading')}</h2>
              <p className="help-modal__info-text">
                <Trans i18nKey="modal.contact">
                  For help with logging in, you can use the FAQ on this page
                  below. Or you can always{' '}
                  <Link to="contact-us" reloadDocument={true}>
                    contact us
                  </Link>
                  .
                </Trans>
              </p>
            </div>
            <div className="help-modal__faq">
              <Faqs
                faqMainId={'a079bbe6-e234-6a16-95eb-ff5900622470'}
                faqMainUrl={'faq-help-modal'}
              />
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

export default HelpModal;
