import './CareTeamCards.scss';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardSecondary from '../CardSecondary/CardSecondary';
import useCareTeamData from '../../hooks/useCareTeamData';
import ThreeColumnCards from '../ThreeColumnCards/ThreeColumnCards';
import IconInformation from '../ShpIcons/IconInformation';
import InformationModal from '../InformationModal/InformationModal';
import { InfoModalContent } from '../../interfaces/InformationModal';
import useEpicApiStore from '../../store/useEpicApiStore';

interface CareTeamCardsProps {}

const CareTeamCards: FC<CareTeamCardsProps> = () => {
  const { t } = useTranslation();
  const { isPPO } = useEpicApiStore();
  const careTeamCards = useCareTeamData();
  const [isModalOpen, setModalOpen] = useState(false);

  const modalContent: InfoModalContent[] = t(
    'home.careTeamCards.informationModal.content',
    {
      returnObjects: true,
    },
  );

  if (isPPO) {
    return <></>;
  }

  return (
    <div className="care-team-cards" data-testid="CareTeamCards">
      <div className="care-team-cards__container">
        <h2 className="care-team-cards__title">
          {t('home.careTeamCards.sectionTitle')}
          <button
            className="information-modal-trigger"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <IconInformation />
            <span className="sr-only">
              {t('home.careTeamCards.information')}
            </span>
          </button>
        </h2>

        <ThreeColumnCards>
          {careTeamCards.map(card => (
            <CardSecondary
              key={card.title}
              title={card.title}
              content={card.subtitle}
              phone={card.subtitle2}
              link={card.link}
              linkText={card.linkText}
              openInNewTab={card.openInNewTab}
            />
          ))}
        </ThreeColumnCards>
        <p className="care-team-cards__disclaimer">
          {t('home.careTeamCards.disclaimer')}
        </p>
      </div>

      {isModalOpen && (
        <InformationModal
          title={t('home.careTeamCards.informationModal.title')}
          isOpen={isModalOpen}
          setIsOpen={setModalOpen}
        >
          <>
            {modalContent.map(item => (
              <p key={item.title}>
                <strong>{item.title}</strong>
                <br />
                {item.description}
              </p>
            ))}
          </>
        </InformationModal>
      )}
    </div>
  );
};

export default CareTeamCards;
