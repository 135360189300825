import './DualCoverage.scss';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../../store/useEpicApiStore';
import DualCoverageCards from '../DualCoverageCards/DualCoverageCards';
import { useWebPortalStore } from '../../store/WebPortalStore';

const DualCoverage = () => {
  const { t } = useTranslation();
  const { userDetails } = useEpicApiStore();
  const { postLoginRedirect } = useWebPortalStore();

  return (
    <div className="dual-coverage" data-testid="dual-coverage">
      <div className="dual-coverage__wrapper">
        <div className="dual-coverage__container">
          <h1 className="dual-coverage__title">
            {t('dashboardNav.accountGreeting', {
              name: userDetails?.firstName || t('dashboardNav.account'),
            })}
          </h1>
          <p className="dual-coverage__info">
            {t('dualCoverageSelector.info')}
          </p>

          <DualCoverageCards redirectURl={postLoginRedirect} />
        </div>
      </div>
    </div>
  );
};

export default DualCoverage;
