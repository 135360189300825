import { useTranslation } from 'react-i18next';

const IconInformation = () => {
  const { t } = useTranslation();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.information')}</title>
      <path
        d="M8.16666 4.83317H9.83332V6.49984H8.16666V4.83317ZM8.16666 8.1665H9.83332V13.1665H8.16666V8.1665ZM8.99999 0.666504C4.39999 0.666504 0.666656 4.39984 0.666656 8.99984C0.666656 13.5998 4.39999 17.3332 8.99999 17.3332C13.6 17.3332 17.3333 13.5998 17.3333 8.99984C17.3333 4.39984 13.6 0.666504 8.99999 0.666504ZM8.99999 15.6665C5.32499 15.6665 2.33332 12.6748 2.33332 8.99984C2.33332 5.32484 5.32499 2.33317 8.99999 2.33317C12.675 2.33317 15.6667 5.32484 15.6667 8.99984C15.6667 12.6748 12.675 15.6665 8.99999 15.6665Z"
        fill="#00548E"
      />
    </svg>
  );
};

export default IconInformation;
