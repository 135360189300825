import { AccountStatus } from '@shp/shp-epic';
import './UserStatusIcon.scss';

interface UserStatusIconProps {
  status: AccountStatus;
  title: string;
}

export default function UserStatusIcon({ status, title }: UserStatusIconProps) {
  return (
    <svg
      className="user-status-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_636_1867)">
        <path
          d="M9.16675 5.83332H10.8334V7.49999H9.16675V5.83332ZM9.16675 9.16666H10.8334V14.1667H9.16675V9.16666ZM10.0001 1.66666C5.40008 1.66666 1.66675 5.39999 1.66675 9.99999C1.66675 14.6 5.40008 18.3333 10.0001 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 9.99999C18.3334 5.39999 14.6001 1.66666 10.0001 1.66666ZM10.0001 16.6667C6.32508 16.6667 3.33341 13.675 3.33341 9.99999C3.33341 6.32499 6.32508 3.33332 10.0001 3.33332C13.6751 3.33332 16.6667 6.32499 16.6667 9.99999C16.6667 13.675 13.6751 16.6667 10.0001 16.6667Z"
          fill="#00548E"
        />
        <title>{title}</title>
      </g>
      <defs>
        <clipPath id="clip0_636_1867">
          <rect
            width="20"
            height="20"
            className={`user-status-icon__status${status}`}
          />
        </clipPath>
      </defs>
    </svg>
  );
}
