import './FooterLanguages.scss';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { languages } from './FooterLanguagesData';

interface TooltipProps {
  content: string;
  tooltipId: string;
  lang: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  tooltipId,
  lang,
  children,
}) => {
  const $el = useRef<HTMLDivElement>(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleLanguageClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();

    setOpenTooltip(!openTooltip);
  };

  useEffect(() => {
    const handleClickOutsideElement = (event: MouseEvent) => {
      if ($el.current && !$el.current.contains(event.target as Node)) {
        setOpenTooltip(false);
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setOpenTooltip(false);
      }
    };

    window.addEventListener('click', handleClickOutsideElement);
    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('click', handleClickOutsideElement);
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <div ref={$el} className="lang-tooltip">
      <button
        className="lang-tooltip__toggle"
        onClick={handleLanguageClick}
        aria-describedby={tooltipId}
        aria-expanded={openTooltip}
        aria-haspopup={openTooltip}
        lang={lang}
      >
        <span aria-hidden="true">{children}</span>
        <span className="sr-only">{content}</span>
      </button>
      {openTooltip && (
        <p
          id={tooltipId}
          className="lang-tooltip__popover"
          aria-label={content}
          lang={lang}
        >
          {content}
        </p>
      )}
    </div>
  );
};

const FooterLanguages: React.FC = () => {
  return (
    <>
      <div id="help-in-other-languages" className="row">
        {languages.map((language, index) => (
          <div className="col-sm-3" key={index}>
            <ul>
              {language.map(({ language, tooltipContent, tooltipId, lang }) => (
                <li key={tooltipId}>
                  <Tooltip
                    content={tooltipContent}
                    tooltipId={tooltipId}
                    lang={lang}
                  >
                    {language}
                  </Tooltip>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default FooterLanguages;
