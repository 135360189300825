import './InformationModal.scss';
import PrimaryContainer from '../PrimaryContainer/PrimaryContainer';
import FocusTrap from 'focus-trap-react';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { InformationModalProps } from '../../interfaces/InformationModal';

const InformationModal = ({
  title,
  children,
  isOpen,
  setIsOpen,
}: InformationModalProps) => {
  const modalId = `information-modal-${title}`;
  const modal = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleModalClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [setIsOpen]);

  useEffect(() => {
    const modalEl = modal.current;

    if (!modalEl) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (modalEl && !modalEl.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modal, setIsOpen]);

  return (
    <FocusTrap
      focusTrapOptions={{
        allowOutsideClick: true,
        fallbackFocus: modal.current || document.body,
      }}
    >
      <div id={modalId} className="information-modal" aria-hidden={!isOpen}>
        <div className="information-modal__wrapper" ref={modal}>
          <PrimaryContainer>
            <>
              <h2 className="information-modal__title">{title}</h2>
              <button
                className="information-modal__close"
                onClick={handleModalClick}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>{t('modal.close')}</title>
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="#00548E"
                  />
                </svg>
              </button>
              <div className="information-modal__content">{children}</div>
            </>
          </PrimaryContainer>
        </div>
      </div>
    </FocusTrap>
  );
};

export default InformationModal;
