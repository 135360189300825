import './PremiumBilling.scss';
import { useTranslation } from 'react-i18next';
import { HomeButtonProps } from '../../interfaces/HomeButton';
import { Helper } from '@shp/shp-epic';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import HomeButton from '../../components/HomeButton/HomeButton';
import useEpicApiStore from '../../store/useEpicApiStore';
import TwoColumnCardGrid from '../../components/TwoColumnCardGrid/TwoColumnCardGrid';
import useFetchBillDownloadLink from '../../hooks/useFetchBillDownloadLink';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import useBillingStatus from '../../hooks/useBillingStatus';
import { Link } from 'react-router-dom';

function PremiumBilling() {
  const { t } = useTranslation();
  const { premium, paperlessBilling } = useEpicApiStore();
  const currentBillLink = useFetchBillDownloadLink();

  const buttons: HomeButtonProps[] = t('billing.buttons', {
    returnObjects: true,
  });

  // for buttons, map and if title == 'Set up autopay' then edit the title to 'Edit autopay' if premium.autoPay is true
  const mappedButtons = buttons.map((button, index) => {
    if (0 === index) {
      return {
        ...button,
        label: premium?.autoPay?.isEnrolled
          ? t('billing.payAutopay')
          : button.label,
      };
    }
    if (1 === index) {
      return {
        ...button,
        label: premium?.autoPay?.isEnrolled
          ? t('billing.editAutopay')
          : button.label,
      };
    }
    return button;
  });

  const cards: ResourceLinkProps[] = t('billing.cards', {
    returnObjects: true,
  });

  // lets map over cards and if title = Current bill then lets use currentBillLink as the link instead
  const mappedCards = cards.map(card => {
    if (card.to === '#') {
      return {
        ...card,
        to: currentBillLink || '',
      };
    }
    return card;
  });

  const currentInvoice = premium?.invoices
    ? premium.invoices[premium.invoices.length - 1]
    : null;

  const formatDateLong = (value: string) => {
    if (!value) {
      return '';
    }
    const monthList = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const splitDate = value.split('/');
    const month = monthList[Number(splitDate[0]) - 1];
    const day = Number(splitDate[1]);
    const year = Number(splitDate[2]);
    return month + ' ' + day + ', ' + year;
  };
  const isPastDue = useBillingStatus({
    dueDate: currentInvoice?.dueDate || '',
  });

  return (
    <BreadcrumbLayout
      pageTitle={t('billing.title')}
      className="billing"
      theme="large"
    >
      <>
        <div className="billing__buttons">
          <div className="container">
            <div className="billing__due">
              {premium?.autoPay?.isEnrolled ? (
                <>
                  <p className="billing__due_text">
                    {t('billing.autoPayment')}:{' '}
                    <b>
                      {Helper.formatCurrency(premium?.amountDue || 0, true)}
                    </b>
                  </p>
                  <p className="billing__due_text">
                    {t('billing.nextPayment')}:{' '}
                    {formatDateLong(premium?.autoPay.scheduledDate)}
                  </p>
                </>
              ) : (
                <>
                  <p className="billing__due_text">
                    <span>
                      {t('billing.amtDue')}:{' '}
                      <b>
                        {Helper.formatCurrency(premium?.amountDue || 0, true)}
                      </b>
                    </span>
                  </p>
                  {(premium?.amountDue || 0) > 0 && (
                    <p className="billing__due_text">
                      {isPastDue
                        ? 'PAST DUE'
                        : `${t('billing.due')} ${formatDateLong(
                            currentInvoice?.dueDate || '',
                          )}`}
                    </p>
                  )}
                </>
              )}
            </div>

            <TwoColumnCardGrid>
              {mappedButtons.map(button => (
                <HomeButton
                  key={button.label}
                  label={button.label}
                  icon={button.icon}
                  link={button.link}
                  openInNewTab={button.openInNewTab}
                />
              ))}
            </TwoColumnCardGrid>
          </div>
        </div>
        <div>
          <div className="container billing__cards">
            <TwoColumnCardGrid>
              <>
                {mappedCards.map((link, index) => (
                  <ResourceLink
                    key={index}
                    text={link.text}
                    to={link.to}
                    openInNewTab={link.openInNewTab}
                  />
                ))}
              </>
            </TwoColumnCardGrid>
          </div>
        </div>
        <div>
          <div className="container billing__guestpay">
            {paperlessBilling && (
              <p className="billing__paperlessbilling">
                {t('billing.paperlessBilling.text')}{' '}
                <Link
                  to={`/${t('routes.billing.index')}/${t(
                    'routes.billing.paperlessBilling',
                  )}`}
                >
                  {t('billing.paperlessBilling.textLink')}
                </Link>
                {'.'}
              </p>
            )}
          </div>
        </div>
      </>
    </BreadcrumbLayout>
  );
}

export default PremiumBilling;
