import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CopayOrCoinsurance, CostShare, CoverageInfo } from '@shp/shp-epic';

const useTranslatedCostShares = (costShares: CostShare | undefined) => {
  const { t } = useTranslation();

  const keyMap = useMemo(
    () =>
      new Map<keyof CoverageInfo, string>([
        ['pcpCopayTier1', 'home.coverage.costShares.types.pcp'],
        ['pcpCopayTier1', 'home.coverage.costShares.types.pcp'],
        ['pcpCoinsuranceTier1', 'home.coverage.costShares.types.pcp'],
        ['specialtyCopayTier1', 'home.coverage.costShares.types.specialist'],
        [
          'specialtyCoinsuranceTier1',
          'home.coverage.costShares.types.specialist',
        ],
        ['erCopayTier1', 'home.coverage.costShares.types.er'],
        ['erCoinsuranceTier1', 'home.coverage.costShares.types.er'],
        ['ucCopayTier1', 'home.coverage.costShares.types.uc'],
        ['ucCoinsuranceTier1', 'home.coverage.costShares.types.uc'],
        ['hospitalCopayTier1', 'home.coverage.costShares.types.inpatient'],
        [
          'hospitalCoinsuranceTier1',
          'home.coverage.costShares.types.inpatient',
        ],
        ['outpatientCopayTier1', 'home.coverage.costShares.types.outpatient'],
        [
          'outpatientCoinsuranceTier1',
          'home.coverage.costShares.types.outpatient',
        ],
        [
          'mentalHealthCopayTier1',
          'home.coverage.costShares.types.mentalHealth',
        ],
        [
          'mentalHealthCoinsuranceTier1',
          'home.coverage.costShares.types.mentalHealth',
        ],
      ]),
    [],
  );

  const createTranslatedCopayOrCoinsurance = useCallback(
    (costShare: CopayOrCoinsurance) => {
      const deductibleApplies = costShare.deductibleApplies;
      const translationKey = keyMap.get(costShare.fdiKey) || '';
      const amount = costShare.amount;
      const isCopay = costShare.fdiKey.includes('Copay');
      const amountStringSuffix = isCopay
        ? t('home.coverage.costShares.copay')
        : t('home.coverage.costShares.coinsurance');
      const amountString = costShare.amountString
        ? `${costShare.amountString} ${amountStringSuffix}`
        : undefined;

      return {
        isCopay,
        translationKey,
        amount,
        amountString,
        deductibleApplies,
      };
    },
    [keyMap, t],
  );

  const createTranslatedCostShare = useCallback(
    (costShare: CostShare) => {
      return {
        tier1: costShare.tier1.map(createTranslatedCopayOrCoinsurance),
        tier2: costShare.tier2.map(createTranslatedCopayOrCoinsurance),
        tier3: costShare.tier3.map(createTranslatedCopayOrCoinsurance),
        hasTiers: costShare.hasTiers,
      };
    },
    [createTranslatedCopayOrCoinsurance],
  );

  const translatedCostShares = useMemo(() => {
    return costShares ? createTranslatedCostShare(costShares) : undefined;
  }, [costShares, createTranslatedCostShare]);

  return translatedCostShares;
};

export default useTranslatedCostShares;
