import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../store/useEpicApiStore';
import { useProxySync } from './useEpicProxyApi';
import { useWebPortalStore } from '../store/WebPortalStore';

interface NavSectionLink {
  name: string;
  link: string;
  openInNewTab: boolean;
  onClick?: () => void;
}

interface NavSection {
  name: string;
  links: NavSectionLink[];
}

const useAccountMenuNavigationData = (): NavSection[] => {
  const { setPostLoginRedirect } = useWebPortalStore();
  const { t } = useTranslation();
  const { userDetails, coverages, resetCoverage, noPlansExperience, members } =
    useEpicApiStore();
  const proxySync = useProxySync();

  let navItems = [
    {
      name: t('accountDropdown.personalInformation'),
      links: [
        {
          name: t('accountDropdown.contactInformation'),
          link: `/${t('routes.account.index')}/${t(
            'routes.account.forms.index',
          )}/${t('routes.account.forms.contactInformation')}`,
          openInNewTab: false,
        },
        {
          name: t('accountDropdown.notificationPreferences'),
          link: `/${t('routes.account.index')}/${t(
            'routes.account.notifications',
          )}`,
          openInNewTab: false,
        },
        {
          name: t('accountDropdown.viewMyPlans'),
          link: `/${t('routes.coverage.viewMyPlans')}`,
          openInNewTab: false,
        },
        {
          name: t('accountDropdown.coveragesAndCoveredMembers'),
          link: `/${t('routes.coverage.index')}/${t(
            'routes.coverage.coveredMembers',
          )}`,
          openInNewTab: false,
        },
        {
          name: t('accountDropdown.languageSettings'),
          link: `/${t('routes.account.index')}/${t(
            'routes.account.languages',
          )}`,
          openInNewTab: false,
        },
      ],
    },
    {
      name: t('accountDropdown.importantDocuments'),
      links: [
        {
          name: t('accountDropdown.documentCenter'),
          link: `/${t('routes.account.index')}/${t(
            'routes.account.documentCenter',
          )}`,
          openInNewTab: false,
        },
        {
          name: t('accountDropdown.accountForms'),
          link: `/${t('routes.account.index')}/${t(
            'routes.account.forms.index',
          )}`,
          openInNewTab: false,
        },
      ],
    },
    {
      name: t('accountDropdown.accountAccessAndControl'),
      links: [
        {
          name: t('accountDropdown.changePassword'),
          link: `/${t('routes.account.index')}/${t(
            'routes.account.changePassword',
          )}`,
          openInNewTab: false,
        },
        {
          name: t('accountDropdown.manageAccess'),
          link: `/${t('routes.account.index')}/${t(
            'routes.account.manageAccess',
          )}`,
          openInNewTab: false,
        },
      ],
    },
    {
      name: t('accountDropdown.sharedAccounts'),
      links: [],
    },
    {
      name: t('accountDropdown.legalNotices'),
      links: [
        {
          name: t('accountDropdown.privacyPolicy'),
          link: t('accountDropdown.links.privacy'),
          openInNewTab: true,
        },
        {
          name: t('accountDropdown.privacyPractices'),
          link: t('accountDropdown.links.privacyPractices'),
          openInNewTab: true,
        },
        {
          name: t('accountDropdown.termsAndConditionsOfUse'),
          link: t('accountDropdown.links.terms'),
          openInNewTab: true,
        },
      ],
    },
  ];

  navItems = filterNavItemsIfOnlyOnePlan(navItems, coverages, t);
  navItems = filterNavItemsIfNoPlansExperience(navItems, noPlansExperience, t);

  const mappedNavItems = navItems.filter(navItem => {
    if (navItem.name === t('accountDropdown.sharedAccounts')) {
      if (Array.isArray(members) && members.length > 1) {
        navItem.links = members
          .filter(_ => _.eid !== userDetails?.eid)
          .map(member => {
            return {
              name: `${member.name}`,
              link: `#`,
              openInNewTab: false,
              onClick: () => {
                resetCoverage();
                setPostLoginRedirect('/');
                proxySync(member.eid);
              },
            };
          });
      } else {
        return false;
      }
    }
    return navItem;
  });

  return mappedNavItems;
};

const filterNavItemsIfOnlyOnePlan = (
  navItems: NavSection[],
  coverages: any,
  t: (key: string) => string,
): NavSection[] => {
  if (coverages?.length === 1) {
    navItems[0].links = navItems[0].links.filter(
      link => link.name !== t('accountDropdown.viewMyPlans'),
    );
  }
  return navItems;
};

const filterNavItemsIfNoPlansExperience = (
  navItems: NavSection[],
  noPlansExperience: boolean | undefined,
  t: (key: string) => string,
): NavSection[] => {
  if (!noPlansExperience) {
    return navItems;
  }

  const itemsToFilterOut = [
    t('accountDropdown.viewMyPlans'),
    t('accountDropdown.coveragesAndCoveredMembers'),
    t('accountDropdown.accountForms'),
  ];

  return navItems.map(item => ({
    ...item,
    links: item.links.filter(link => !itemsToFilterOut.includes(link.name)),
  }));
};

export default useAccountMenuNavigationData;
