import './PayNow.scss';
import EpicIframe from '../../components/EpicIframe/EpicIframe';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../../store/useEpicApiStore';
import useLoginStore from '../../store/LoginStore';
import useFHIRAuth from '../../hooks/useFHIRAuth';
import { useEffect } from 'react';
import { useWebPortalStore } from '../../store/WebPortalStore';

const PayNow = () => {
  const { t } = useTranslation();
  const { premium } = useEpicApiStore();
  const { setPostLoginRedirect } = useWebPortalStore();
  const { setAuthorizeToken, needsDataRefresh, setNeedsDataRefresh } =
    useLoginStore();
  const { authorizeToken: fhirAuthToken, error } = useFHIRAuth();
  const redirectUrl = t('routes.billing.index');

  useEffect(() => {
    if (needsDataRefresh) {
      if (fhirAuthToken) {
        setPostLoginRedirect(redirectUrl);
        setAuthorizeToken(null);
        setNeedsDataRefresh(false);
        window.location.reload();
      } else if (error) {
        console.error('Failed to fetch FHIR auth token:', error);
      }
    }
  }, [
    needsDataRefresh,
    fhirAuthToken,
    setAuthorizeToken,
    setNeedsDataRefresh,
    error,
    setPostLoginRedirect,
    redirectUrl,
  ]);

  if (!premium?.hasBilling) {
    return <></>;
  }

  const currentInvoice = premium?.invoices
    ? premium.invoices[premium.invoices.length - 1]
    : null;

  return (
    <div data-testid="pay-now-page">
      <BreadcrumbLayout
        pageTitle={`${t('routes.billing.payNow')}`}
        className="paynow-page"
      >
        <div className="container">
          <div className="paynow-page__data-tile">
            <EpicIframe
              title={`${t('routes.billing.payNow')}`}
              url={`Insurance/PremiumPayment/PaymentLanding?id=${currentInvoice?.invoiceId}`}
              height={1600}
              width={960}
              refetchEpicDataOnMessage="RefreshBillingData"
            />
          </div>
        </div>
      </BreadcrumbLayout>
    </div>
  );
};

export default PayNow;
