// Breadcrumb.tsx
import React, { useMemo } from 'react';
import './Breadcrumbs.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type BreadcrumbItem = {
  label: string;
  url?: string;
};

const Breadcrumbs: React.FC = () => {
  const { t } = useTranslation();
  const items = useMemo<BreadcrumbItem[]>(() => {
    const pathParts = decodeURIComponent(window.location.pathname)
      .split('/')
      .filter(part => part !== '');
    const breadcrumbItems: BreadcrumbItem[] = [
      { label: t('home.pageName'), url: '/' },
    ];

    pathParts.forEach((part, index) => {
      const url = '/' + pathParts.slice(0, index + 1).join('/');
      const label = part.replace(/-/g, ' ');
      const formattedLabel = label.charAt(0).toUpperCase() + label.slice(1);
      breadcrumbItems.push({
        label: formattedLabel,
        url: index < pathParts.length - 1 ? url : undefined,
      });
    });

    return breadcrumbItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <nav aria-label="breadcrumb" className="breadcrumbs">
      <ol className="breadcrumbs__list">
        {items.map((item, index) => (
          <React.Fragment key={item.label}>
            <li
              className={`breadcrumbs__item${
                index === items.length - 1 ? ' breadcrumbs__item--active' : ''
              }`}
              aria-current={index === items.length - 1 ? 'page' : false}
            >
              {item.url ? (
                <Link to={item.url} className="breadcrumbs__link">
                  {item.label}
                </Link>
              ) : (
                item.label
              )}
            </li>
            {index < items.length - 1 && (
              <li className="breadcrumbs__separator">
                <svg
                  width="6"
                  height="11"
                  viewBox="0 0 6 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>{t('icon.rightArrow')}</title>
                  <path
                    d="M-7.8881e-08 10.0977L0.902294 11L6 5.90229L0.902293 0.804589L-8.1243e-07 1.70688L4.19541 5.90229L-7.8881e-08 10.0977Z"
                    fill="#00548E"
                  />
                </svg>
              </li>
            )}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
