import './DualCoverageCards.scss';
import useEpicApiStore from '../../store/useEpicApiStore';
import AccountStatusLabel from '../AccountStatusLabel/AccountStatusLabel';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWebPortalStore } from '../../store/WebPortalStore';

interface Props {
  redirectURl?: string;
}

const DualCoverageCards = ({ redirectURl }: Props) => {
  const { t } = useTranslation();
  const { coverages, changeCoverage } = useEpicApiStore();
  const { setPostLoginRedirect } = useWebPortalStore();
  const navigate = useNavigate();

  const handleUpdateCoverage = (coverageId: string) => {
    changeCoverage(coverageId);

    if (redirectURl) {
      navigate(redirectURl);
      setPostLoginRedirect(undefined);
    } else {
      navigate(`/${t('routes.home')}`);
    }
  };

  return (
    <div className="dual-coverage-cards" data-testid="dual-coverage-cards">
      {coverages?.map(coverage => (
        <button
          className="dual-coverage-cards__card"
          key={coverage.coverageId}
          onClick={() => handleUpdateCoverage(coverage.coverageId ?? '')}
        >
          <div className="dual-coverage-cards__card-header">
            <h3 className="dual-coverage-cards__card-title">
              Sharp Heath Plan / {coverage.planInfo.planName}
            </h3>
            <AccountStatusLabel accountStatus={coverage.accountStatus} />
          </div>
          <dl className="dual-coverage-cards__card-content">
            <div className="dual-coverage-cards__card-details">
              <dt>Plan Effective Date</dt>
              <dd>{coverage.effectiveDate}</dd>
            </div>
            <div className="dual-coverage-cards__card-details">
              <dt>Subscriber Name</dt>
              <dd>
                {coverage.memberInfo.firstName} {coverage.memberInfo.lastName}
              </dd>
            </div>
            <div className="dual-coverage-cards__card-details">
              <dt>Subscriber Number</dt>
              <dd>{coverage.memberInfo.id}</dd>
            </div>
            <div className="dual-coverage-cards__card-details">
              <dt>Group Name</dt>
              <dd>
                {(coverage.provider.pmgName && (
                  <>
                    <div>{coverage.provider.pmgName}</div>
                    <div>({coverage.provider.npi})</div>
                  </>
                )) ||
                  'N/A'}
              </dd>
            </div>
          </dl>
        </button>
      ))}
    </div>
  );
};

export default DualCoverageCards;
