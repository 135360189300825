import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useWebPortalStore } from '../store/WebPortalStore';
import useLoginStore from '../store/LoginStore';
import useEpicApiStore from '../store/useEpicApiStore';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

const useHandleRouteChange = () => {
  const { t } = useTranslation();
  const { pathname, hash, search } = useLocation();
  const {
    setIsMobileMenuOpen,
    setIsAccountDropdownOpen,
    setIsMobileAccountDropdownOpen,
    setPostLoginRedirect,
  } = useWebPortalStore(
    useShallow(state => ({
      setIsMobileMenuOpen: state.setIsMobileMenuOpen,
      setIsAccountDropdownOpen: state.setIsAccountDropdownOpen,
      setIsMobileAccountDropdownOpen: state.setIsMobileAccountDropdownOpen,
      setPostLoginRedirect: state.setPostLoginRedirect,
    })),
  );

  const isLoggedIn = useLoginStore(state => state.isLoggedIn);
  const { isCoverageSelected } = useEpicApiStore(
    useShallow(state => ({
      isCoverageSelected: state.isCoverageSelected,
    })),
  );

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }

    setIsMobileMenuOpen(false);
    setIsMobileAccountDropdownOpen(false);
    setIsAccountDropdownOpen(false);

    if (isLoggedIn && isCoverageSelected) {
      if (pathname !== t('routes.home') && pathname !== '/') {
        setPostLoginRedirect(pathname + search);
      } else if (pathname === t('routes.home')) {
        setPostLoginRedirect('/');
      }
    }

    // SHP will need to setup a History Change trigger in GTM
    // to view pages views that are pushed and to capture our SPA navigation
    const tagManagerArgs = {
      dataLayer: {
        pageview: 'pageview',
        page: pathname,
      },
      dataLayerName: 'PageDataLayer',
    };

    TagManager.dataLayer(tagManagerArgs);
  }, [
    pathname,
    setIsMobileMenuOpen,
    setIsAccountDropdownOpen,
    hash,
    setIsMobileAccountDropdownOpen,
    setPostLoginRedirect,
    isLoggedIn,
    search,
    isCoverageSelected,
    t,
  ]);
};

export default useHandleRouteChange;
