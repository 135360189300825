import './BehavioralHealth.scss';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import { useTranslation } from 'react-i18next';
import TwoColumnCardGrid from '../../components/TwoColumnCardGrid/TwoColumnCardGrid';
import HomeButton from '../../components/HomeButton/HomeButton';
import { HomeButtonProps } from '../../interfaces/HomeButton';
import useEpicApiStore from '../../store/useEpicApiStore';
import ThreeColumnCards from '../../components/ThreeColumnCards/ThreeColumnCards';
import CardSecondary from '../../components/CardSecondary/CardSecondary';
import { CardSecondaryProps } from '../../interfaces/CardSecondary';
import PrimaryIconLink from '../../components/PrimaryIconLink/PrimaryIconLink';
import { PrimaryIconLinkProps } from '../../interfaces/PrimaryIconLink';
import IconPreventionWellness from '../../components/ShpIcons/IconPreventionWellness';
import { getEpicUrl } from '../../helpers/urlUtils';

const BehavioralHealth = () => {
  const { t } = useTranslation();
  const { hasMedicare, isCalpers, isCalpersEGWP } = useEpicApiStore();
  const epicUrl = getEpicUrl();

  const buttons: HomeButtonProps[] = t('behavioralHealth.buttons', {
    returnObjects: true,
  });
  buttons.forEach(button => {
    if (button.link.includes('inside.asp')) {
      button.link = epicUrl + button.link;
    }
    if (button.medicareLink?.includes('inside.asp')) {
      button.medicareLink = epicUrl + button.medicareLink;
    }
  });

  const resourceCards: CardSecondaryProps[] = t(
    'behavioralHealth.resources.cards',
    {
      returnObjects: true,
    },
  );

  const resourceCardElements = resourceCards.map(card => {
    let link = card.link;
    if (isCalpersEGWP && card.egwpLink) {
      link = card.egwpLink;
    } else if (hasMedicare && card.medicareLink) {
      link = card.medicareLink;
    } else if (isCalpers && card.calpersLink) {
      link = card.calpersLink;
    }
    return <CardSecondary key={card.title} {...card} link={link} />;
  });

  const ctaWellness: PrimaryIconLinkProps = t('behavioralHealth.ctaWellness', {
    returnObjects: true,
  });

  return (
    <BreadcrumbLayout
      className="behavioral-health-page"
      pageTitle={t('behavioralHealth.pageName')}
    >
      <>
        <div className="behavioral-health-page__buttons">
          <div className="container">
            <TwoColumnCardGrid>
              {buttons.map(button => (
                <HomeButton
                  key={button.label}
                  label={
                    hasMedicare && button.medicareLabel
                      ? button.medicareLabel
                      : button.label
                  }
                  icon={button.icon}
                  link={
                    hasMedicare && button.medicareLink
                      ? button.medicareLink
                      : button.link
                  }
                  openInNewTab={button.openInNewTab}
                />
              ))}
            </TwoColumnCardGrid>
          </div>
        </div>
        <div className="container behavioral-health-page__resources">
          <h2 className="behavioral-health-page__resources-heading">
            {t('behavioralHealth.resources.title')}
          </h2>
          <ThreeColumnCards>{resourceCardElements}</ThreeColumnCards>
        </div>
        <div className="behavioral-health-page__callouts">
          <div className="container">
            <PrimaryIconLink {...ctaWellness}>
              <IconPreventionWellness />
            </PrimaryIconLink>
          </div>
        </div>
      </>
    </BreadcrumbLayout>
  );
};

export default BehavioralHealth;
