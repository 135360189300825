import { useTranslation } from 'react-i18next';
import PrivateFormPage from './PrivateFormPage/PrivateFormPage';

const ManageAccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <PrivateFormPage
        title={`${t('account.manageAccess.title')}`}
        src="Proxies/FamilyAccess"
        height={800}
      />
      {/* TODO: Have access to other member’s accounts? */}
    </>
  );
};

export default ManageAccess;
