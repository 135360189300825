import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import {
  launchFHIRAuthorization,
  launchFHIRLaunchToken,
  requestFHIRAccessToken,
  isValidJWTToken,
} from '../helpers/fhirUtils';
import { useNavigate } from 'react-router-dom';
import useLoginStore from '../store/LoginStore';
import { useQuery } from '@tanstack/react-query';
import useEpicApiStore from '../store/useEpicApiStore';
import { useTranslation } from 'react-i18next';
import { useWebPortalStore } from '../store/WebPortalStore';
import { Buffer } from 'buffer';

const useFHIRAuth = () => {
  const { t } = useTranslation();
  const { postLoginRedirect } = useWebPortalStore();
  const [error, setError] = useState<string | null>(null);
  const { setData } = useEpicApiStore();
  const { authorizeToken, setAuthorizeToken } = useLoginStore();
  const navigate = useNavigate();
  const [code, setCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasRun = useRef<boolean>(false);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const fetchData = useCallback(async () => {
    if (isLoading || hasRun.current) return;
    setIsLoading(true);

    const code = query.get('code');
    const error = query.get('error');
    const error_description = query.get('error_description');
    const iss = query.get('iss');
    const launch = query.get('launch');

    if (code) {
      setCode(code);
    } else if (launch && iss) {
      launchFHIRAuthorization(launch, iss);
    } else if (error) {
      console.error(error, error_description);
      setError(error_description);
      launchFHIRLaunchToken();
    } else {
      launchFHIRLaunchToken();
    }

    hasRun.current = true;
    setIsLoading(false);
  }, [query, isLoading]);

  const base64Decode = useCallback((input: string) => {
    const uriDecodedInput = decodeURIComponent(input);
    const buffer = Buffer.from(uriDecodedInput, 'base64');
    const decodedInput = buffer.toString('utf8');
    return decodedInput;
  }, []);

  useEffect(() => {
    if (authorizeToken) {
      try {
        if (isValidJWTToken(authorizeToken.access_token)) {
          setAuthorizeToken(authorizeToken, () => {
            setData(base64Decode(authorizeToken.context_string));
          });
        } else {
          fetchData();
        }
      } catch (error) {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [authorizeToken, fetchData, setAuthorizeToken, setData, base64Decode]);

  const { data } = useQuery({
    queryKey: ['accessToken', code],
    queryFn: () => requestFHIRAccessToken(code as string),
    enabled: code !== null,
  });

  useEffect(() => {
    if (data) {
      setAuthorizeToken(data, () => {
        if (data) {
          setData(base64Decode(data.context_string));
        }
      });

      if (postLoginRedirect && postLoginRedirect !== '/') {
        navigate(postLoginRedirect);
      } else {
        navigate(`/${t('routes.home')}`);
      }
    }
  }, [
    data,
    postLoginRedirect,
    navigate,
    setAuthorizeToken,
    setData,
    t,
    base64Decode,
  ]);

  return { authorizeToken, error };
};

export default useFHIRAuth;
