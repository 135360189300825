import './PharmacyForms.scss';
import { useTranslation } from 'react-i18next';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';
import TwoColumnCardGrid from '../../components/TwoColumnCardGrid/TwoColumnCardGrid';

const PharmacyForms = () => {
  const { t } = useTranslation();

  const formCards: ResourceLinkProps[] = t('pharmacyForms.forms', {
    returnObjects: true,
  });

  return (
    <div>
      <BreadcrumbLayout
        pageTitle={`${t('pharmacyForms.title')}`}
        className="pharmacy-forms"
      >
        <>
          <div>
            <div className="container pharmacy-forms__forms">
              <TwoColumnCardGrid>
                {formCards.map((form: ResourceLinkProps, index) => (
                  <ResourceLink {...form} key={index} />
                ))}
              </TwoColumnCardGrid>
            </div>
          </div>
        </>
      </BreadcrumbLayout>
    </div>
  );
};

export default PharmacyForms;
