import './Account.scss';
import EpicIframe from '../../components/EpicIframe/EpicIframe';
import PersonalUpdates from '../../components/PersonalUpdates/PersonalUpdates';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import { useTranslation } from 'react-i18next';

const Account = () => {
  const { t } = useTranslation();

  return (
    <BreadcrumbLayout
      pageTitle={`${t('account.personalInformation.title')}`}
      className="account-page"
    >
      <div className="container">
        <div className="account-page__data-tile">
          <EpicIframe
            title={`${t('account.personalInformation.title')}`}
            url="PersonalInformation"
            height={1600}
            width={960}
          />
        </div>
        <div className="account-page__cards">
          <PersonalUpdates hasHeading={true} />
        </div>
      </div>
    </BreadcrumbLayout>
  );
};

export default Account;
