import { Link } from 'react-router-dom';

type Props = {
  text: string;
  to: string;
};

const LinkSecondary = (props: Props) => {
  return (
    <Link
      to={props.to}
      className="secondary-button"
      data-testid="link-secondary"
    >
      {props.text}
    </Link>
  );
};

export default LinkSecondary;
