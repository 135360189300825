import { useTranslation } from 'react-i18next';

const IconHeart = () => {
  const { t } = useTranslation();

  return (
    <svg
      width="67"
      height="50"
      viewBox="0 0 67 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.heart')}</title>
      <g clipPath="url(#clip0_246_698)">
        <path
          d="M38.5382 5.19232L37.9292 5.71729C37.4618 6.12875 37.4193 6.82399 37.8159 7.2922C38.2125 7.76042 38.9348 7.80299 39.388 7.40571L39.997 6.88074C40.4644 6.46928 40.5069 5.77404 40.1104 5.30583C39.7138 4.83761 39.0056 4.79504 38.5382 5.19232Z"
          fill="#00548E"
        />
        <path
          d="M26.2587 47.0914C28.454 48.2974 31.0175 49.2622 32.7454 49.8865L33.1279 48.8365L32.5897 49.8155L32.6605 49.8581C32.9437 50.0142 33.2836 50.0426 33.5952 49.9291C41.64 46.9779 49.7414 41.6004 55.9024 35.3008C58.9758 32.151 61.5677 28.7741 63.4089 25.3405C65.2501 21.8927 66.3265 18.3882 66.3265 14.9688C66.3265 12.9257 65.9299 10.9109 65.066 9.00965C64.202 7.1084 62.8849 5.34903 61.0861 3.75993C59.5707 2.42622 57.9985 1.4756 56.3981 0.879682C54.8118 0.255392 53.1972 0 51.6251 0C47.9568 0 44.5009 1.40465 41.5692 3.16402C41.0451 3.47616 40.8752 4.1714 41.1867 4.71056C41.4983 5.23553 42.1923 5.40579 42.7164 5.09364C45.4357 3.46197 48.5233 2.24177 51.6109 2.24177C52.9422 2.24177 54.2736 2.46879 55.6049 2.96538C56.9363 3.47616 58.2676 4.27071 59.6131 5.43417C61.1994 6.83882 62.3042 8.34279 63.0265 9.93189C63.7488 11.521 64.0746 13.1952 64.0887 14.9546C64.0887 17.8916 63.1398 21.0698 61.426 24.2764C58.8625 29.0863 54.5993 33.9387 49.5289 38.0817C44.4585 42.2389 38.5807 45.7009 32.8163 47.815L33.1987 48.8649L33.7369 47.8859L33.6661 47.8434C33.6661 47.8434 33.5669 47.7866 33.5103 47.7724C31.8107 47.1481 29.3321 46.2117 27.3351 45.1192C26.7969 44.8212 26.117 45.0199 25.8196 45.559C25.5222 46.0982 25.7205 46.7792 26.2587 47.0772V47.0914Z"
          fill="#00548E"
        />
        <path
          d="M21.7973 44.4089L22.9586 45.1892C23.4685 45.5297 24.1767 45.3879 24.5166 44.8771C24.8565 44.3663 24.7149 43.6711 24.205 43.3163L23.0436 42.536C22.5337 42.1955 21.8397 42.3232 21.4857 42.8481C21.1457 43.3589 21.2874 44.0683 21.7973 44.4089Z"
          fill="#00548E"
        />
        <path
          d="M13.4693 38.1807C15.0414 39.5712 16.5285 40.763 18.7805 42.3238C19.2904 42.6785 19.9844 42.5508 20.3384 42.04C20.6925 41.5292 20.565 40.834 20.0552 40.4793C17.8457 38.9327 16.4577 37.8402 14.9422 36.4923C14.4748 36.0809 13.7667 36.1234 13.3701 36.5916C12.9594 37.0599 13.0019 37.7693 13.4693 38.1807Z"
          fill="#00548E"
        />
        <path
          d="M28.5106 5.78882C26.7544 4.38417 24.6582 2.96532 22.3213 1.87282C19.9844 0.780307 17.4208 0.0283203 14.7298 0.0283203C13.1577 0.0283203 11.5431 0.283712 9.95677 0.893814C8.35632 1.48973 6.7842 2.42616 5.26873 3.75988C3.45584 5.33479 2.13865 7.09415 1.27469 8.98121C0.410735 10.8683 0.014164 12.883 0.014164 14.9262C0.014164 18.4165 1.16139 22.0204 3.07343 25.5533C4.99963 29.072 7.70481 32.534 10.9057 35.7406C11.3448 36.1804 12.0529 36.1804 12.492 35.7406C12.9311 35.3007 12.9311 34.5913 12.492 34.1515C9.4044 31.0726 6.82669 27.7667 5.04212 24.475C3.24339 21.1833 2.25196 17.9199 2.25196 14.9262C2.25196 13.1668 2.59188 11.4926 3.3142 9.91765C4.03653 8.34273 5.14126 6.83876 6.74171 5.46249C8.07306 4.29903 9.41857 3.51867 10.7499 3.00789C12.0813 2.51129 13.4126 2.29847 14.7439 2.28428C17.0101 2.28428 19.2762 2.92276 21.3865 3.91595C23.511 4.90914 25.4655 6.22866 27.1226 7.54818C27.6042 7.93127 28.3123 7.86033 28.6948 7.36373C29.0772 6.88133 29.0063 6.17191 28.5106 5.78882Z"
          fill="#00548E"
        />
        <path
          d="M45.0958 19.5797V18.4589H39.5013V12.2869C39.5013 11.9889 39.388 11.7052 39.1756 11.4924C38.9631 11.2795 38.6799 11.166 38.3824 11.166H27.7458C27.4484 11.166 27.1651 11.2795 26.9527 11.4924C26.7402 11.7052 26.6269 11.9889 26.6269 12.2869V18.4589H20.4659C20.1685 18.4589 19.8852 18.5724 19.6728 18.7852C19.4603 18.998 19.347 19.2818 19.347 19.5797V30.2353C19.347 30.5332 19.4603 30.817 19.6728 31.0298C19.8852 31.2426 20.1685 31.3561 20.4659 31.3561H26.6269V37.5281C26.6269 37.8261 26.7402 38.1098 26.9527 38.3227C27.1651 38.5355 27.4484 38.649 27.7458 38.649H38.3824C38.6799 38.649 38.9631 38.5355 39.1756 38.3227C39.388 38.1098 39.5013 37.8261 39.5013 37.5281V31.3561H45.0958C45.3932 31.3561 45.6765 31.2426 45.889 31.0298C46.1014 30.817 46.2147 30.5332 46.2147 30.2353V19.5797C46.2147 19.2818 46.1014 18.998 45.889 18.7852C45.6765 18.5724 45.3932 18.4589 45.0958 18.4589V19.5797H43.9769V29.1144H38.3824C38.085 29.1144 37.8017 29.2279 37.5893 29.4407C37.3768 29.6535 37.2635 29.9373 37.2635 30.2353V36.4072H28.8647V30.2353C28.8647 29.9373 28.7514 29.6535 28.539 29.4407C28.3265 29.2279 28.0432 29.1144 27.7458 29.1144H21.5848V20.7006H27.7458C28.0432 20.7006 28.3265 20.5871 28.539 20.3743C28.7514 20.1615 28.8647 19.8777 28.8647 19.5797V13.4078H37.2635V19.5797C37.2635 19.8777 37.3768 20.1615 37.5893 20.3743C37.8017 20.5871 38.085 20.7006 38.3824 20.7006H45.0958V19.5797Z"
          fill="#00548E"
        />
      </g>
      <defs>
        <clipPath id="clip0_246_698">
          <rect width="66.3265" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconHeart;
