import './SearchCareBlock.scss';
import { useTranslation } from 'react-i18next';
import SearchCareLinks from '../SearchCareLinks/SearchCareLinks';

const SearchCareBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="search-care">
      <h2 className="search-care__heading">
        {t('findCare.searchForCare.title')}
      </h2>
      <SearchCareLinks />
    </div>
  );
};

export default SearchCareBlock;
