import { getEpicProxyRedirectValue, getEpicUrl } from '../helpers/urlUtils';
import useLoginStore from '../store/LoginStore';

const useProxySync = () => {
  const { setAuthorizeToken } = useLoginStore();

  const proxySync = async (id: string | undefined) => {
    const epicUrl = getEpicUrl();
    const optionValue = getEpicProxyRedirectValue();
    if (epicUrl && id) {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `${epicUrl}inside.asp?mode=proxysyncpost`;

      const memberIdInput = document.createElement('input');
      memberIdInput.type = 'hidden';
      memberIdInput.name = 'epi';
      memberIdInput.value = id.toString();
      form.appendChild(memberIdInput);

      const destination = document.createElement('input');
      destination.type = 'hidden';
      destination.name = 'destination';
      destination.value = `mode_fdiredirect&option=${optionValue}`;
      form.appendChild(destination);

      document.body.appendChild(form);

      setTimeout(() => form.submit(), 100);
      setAuthorizeToken(null);
    }
  };

  return proxySync;
};

export { useProxySync };
