import axios, { CreateAxiosDefaults } from 'axios';

const env = process.env;

const config = {
  baseURL: env.REACT_APP_SHP_CMS_API_STAGE,
} as CreateAxiosDefaults;

if (env.REACT_APP_PROD_DOMAIN === window.location.origin) {
  config.baseURL = env.REACT_APP_SHP_CMS_API_PROD;
}

const api = axios.create(config);

export default api;
