import './CareTeamBlock.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconRightArrow from '../../components/IconRightArrow/IconRightArrow';
import useCareTeamData from '../../hooks/useCareTeamData';

const CareTeamBlock = () => {
  const { t } = useTranslation();
  const careTeam = useCareTeamData();

  return (
    <div className="care-team">
      <h2 className="care-team__heading">{t('findCare.careTeam.title')}</h2>
      <ul className="care-team__info">
        {careTeam.map(item => (
          <li className="care-team__item" key={item.title}>
            <h3 className="care-team__title">{item.title}</h3>
            <p className="care-team__text">
              {item.subtitle}

              {(item.subtitle2 && (
                <span className="care-team__subtitle2">{item.subtitle2}</span>
              )) ||
                ''}
            </p>
          </li>
        ))}
      </ul>
      <Link to={`/${t('routes.changePcp')}`} className="care-team__link">
        <span>{t('findCare.careTeam.link')}</span>
        <IconRightArrow />
      </Link>
    </div>
  );
};

export default CareTeamBlock;
