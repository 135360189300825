import { useTranslation } from 'react-i18next';

const IconPreventionWellness = () => {
  const { t } = useTranslation();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="50" fill="none">
      <title>{t('icon.preventionWellness')}</title>
      <g fill="#00548E">
        <path d="M50.234 50c-7.276 0-18.386-2.73-26.337-10.331-4.477-4.284-9.089-7.482-13.146-10.302C3.728 24.495-1.347 20.98.315 15.597 1.482 11.81 8.685 3.574 13.865.904 15.542.045 16.95-.21 18.028.166c1.677.573 2.92 2.745 4.357 5.264 1.123 1.961 2.29 3.997 3.713 5.37.434.422.838.618 1.303.588 1.212-.06 2.62-1.735 3.848-3.213 1.497-1.795 2.92-3.469 4.522-3.077 1.377.347 2.335 2.157 3.144 5.853 2.875 13.288 7.546 21.146 14.27 24.042 4.7 2.02 7.905 5.64 7.8 8.808-.06 1.674-1.033 3.997-5.315 5.49-1.318.453-3.19.71-5.436.71ZM16.964 1.51c-.643 0-1.467.256-2.425.754C9.403 4.903 2.71 12.896 1.752 16.049c-1.333 4.328 3.114 7.42 9.867 12.096 4.103 2.85 8.76 6.079 13.326 10.438 10.66 10.196 25.963 10.769 30.26 9.276 1.932-.664 4.238-1.931 4.313-4.118.09-2.519-2.815-5.611-6.888-7.36-7.202-3.093-12.158-11.313-15.138-25.114-.868-4.012-1.721-4.63-2.05-4.72-.72-.167-1.992 1.357-3.025 2.594-1.498 1.794-3.055 3.635-4.912 3.74-.853.06-1.662-.286-2.41-.995-1.587-1.509-2.86-3.74-3.983-5.702-1.183-2.066-2.396-4.193-3.549-4.585-.18-.06-.374-.09-.583-.09h-.015Z" />
        <path d="M48.647 44.932c-3.788 0-8.474-.83-13.655-3.891-16.036-9.442-24.676-20.045-28.404-25.505a.759.759 0 0 1 .195-1.056.727.727 0 0 1 1.033.196c3.653 5.37 12.128 15.761 27.91 25.068 8.534 5.037 15.362 3.71 19.434 2.926a27.11 27.11 0 0 1 1.633-.287.735.735 0 0 1 .838.649c.06.407-.224.8-.644.844-.449.06-.958.166-1.557.272-1.692.332-4.013.77-6.813.77l.03.014Z" />
        <path d="M15.662 19.306a.853.853 0 0 1-.375-.09.752.752 0 0 1-.284-1.026c2.935-5.234-.928-11.629-.973-11.69-.225-.346-.105-.814.24-1.04.344-.226.808-.106 1.032.241.18.287 4.387 7.21 1.004 13.228a.747.747 0 0 1-.66.377h.016Zm18.476 1.976a.751.751 0 0 1-.733-.603.765.765 0 0 1 .584-.89l4.791-.996c.404-.075.809.181.883.589a.766.766 0 0 1-.583.89l-4.792.995s-.105.015-.15.015Zm2.097 4.103a.751.751 0 0 1-.734-.604c-.075-.407.18-.814.584-.89l4.791-.995a.752.752 0 0 1 .884.588c.075.407-.18.8-.584.89l-4.792.995h-.15v.016Zm2.949 4.087a.751.751 0 0 1-.733-.603c-.075-.407.18-.815.584-.89l4.791-.996a.752.752 0 0 1 .883.589c.075.407-.18.814-.584.89l-4.79.995h-.15v.015Zm2.141 4.103a.752.752 0 0 1-.733-.604c-.075-.407.18-.814.584-.89l4.791-.995a.752.752 0 0 1 .883.588c.075.407-.18.815-.584.89l-4.79.995h-.15v.016Zm5.091 6.832a4.61 4.61 0 0 1-1.123-.12c-.539-.121-1.063-.272-1.587-.408-2.515-.694-4.671-1.282-8.025.347a.746.746 0 0 1-1.003-.347.757.757 0 0 1 .344-1.01c3.848-1.886 6.498-1.147 9.059-.453.494.136 1.003.272 1.527.392 2.366.559 6.378-1.704 7.726-2.624a.73.73 0 0 1 1.033.211.755.755 0 0 1-.21 1.04c-.194.137-4.402 2.957-7.77 2.957l.03.015ZM36.28 15.852c-3.654 0-9.733-2.338-11.23-3.997-.285-.302-.255-.784.045-1.07a.765.765 0 0 1 1.063.044c1.542 1.705 9.133 4.194 11.32 3.349a.73.73 0 0 1 .958.437.738.738 0 0 1-.434.966c-.465.18-1.049.256-1.722.256v.015Z" />
      </g>
    </svg>
  );
};

export default IconPreventionWellness;
