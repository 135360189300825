import useLoginStore from '../store/LoginStore';
import useEpicApiStore from '../store/useEpicApiStore';

const useLogout = () => {
  const { handleLogout } = useLoginStore();
  const { wipeData } = useEpicApiStore();

  const enhancedHandleLogout = () => {
    handleLogout();
    wipeData();
  };

  return enhancedHandleLogout;
};

export default useLogout;
