import './SearchCareLinks.scss';
import { useHealthTrio } from '../../hooks/useHealthTrio';
import { useTranslation } from 'react-i18next';
import { useSupplementCare } from '../../hooks/useSupplementalCare';
import LinkList from '../LinkList/LinkList';
import useEpicApiStore from '../../store/useEpicApiStore';

type SupplementLink = {
  text: string;
  to: string;
};

const SearchCareLinks = () => {
  const { t } = useTranslation();
  const { isPPO } = useEpicApiStore();
  const {
    findHospitalSearch,
    urgentCareSearch,
    doctorSearch,
    labSearch,
    specialistSearch,
    behavioralHealthSearch,
  } = useHealthTrio();
  const supplementalCare = useSupplementCare(true);

  const links = [
    {
      to: doctorSearch,
      text: t('findCare.searchForCare.links.doctorSearch'),
      openInNewTab: true,
    },
    {
      to: specialistSearch,
      text: t('findCare.searchForCare.links.specialistSearch'),
      openInNewTab: true,
    },
    {
      to: behavioralHealthSearch,
      text: t('findCare.searchForCare.links.behavioralHealth'),
      openInNewTab: true,
    },
    {
      to: urgentCareSearch,
      text: t('findCare.searchForCare.links.urgentCareSearch'),
      openInNewTab: true,
    },
    {
      to: findHospitalSearch,
      text: t('findCare.searchForCare.links.hospitalSearch'),
      openInNewTab: true,
    },
    {
      to: labSearch,
      text: t('findCare.searchForCare.links.labSearch'),
      openInNewTab: true,
    },
  ];

  if (!isPPO && supplementalCare) {
    supplementalCare.forEach((item: SupplementLink) => {
      links.push({
        to: item.to,
        text: item.text,
        openInNewTab: true,
      });
    });
  }

  return <LinkList links={links} columns={2} />;
};

export default SearchCareLinks;
