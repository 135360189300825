import { useTranslation } from 'react-i18next';

const IconProfile = () => {
  const { t } = useTranslation();
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.profile')}</title>
      <path
        d="M15.0532 28.7031C22.5916 28.7031 28.7027 22.5921 28.7027 15.0537C28.7027 7.51535 22.5916 1.4043 15.0532 1.4043C7.51486 1.4043 1.40381 7.51535 1.40381 15.0537C1.40381 22.5921 7.51486 28.7031 15.0532 28.7031Z"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0533 17.1939C17.8947 17.1939 20.1981 14.8905 20.1981 12.0491C20.1981 9.2077 17.8947 6.9043 15.0533 6.9043C12.2119 6.9043 9.90851 9.2077 9.90851 12.0491C9.90851 14.8905 12.2119 17.1939 15.0533 17.1939Z"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.15576 26.8295V23.9542C8.15576 21.6928 11.241 19.8594 15.0532 19.8594"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0532 19.8594C18.8654 19.8594 21.9587 21.6928 21.9587 23.9542V26.8214"
        stroke="#00548E"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconProfile;
