import './MainLayout.scss';

type MainLayoutProps = {
  children: JSX.Element;
};

export default function MainLayout({ children }: MainLayoutProps) {
  return (
    <div className="main-layout" data-testid="main-layout">
      <h1 className="sr-only">Sharp Health Plan</h1>
      {children}
    </div>
  );
}
