import './NavigationFooter.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useHealthTrio } from '../../hooks/useHealthTrio';
import { usePharmacyData } from '../../hooks/usePharmacy';

type NavigationItem = {
  Url: string;
  TitlesPath: string;
};

type Column = {
  Properties: {
    CssClass: string;
    SerializedSelectedPages: string;
  };
};

type ProcessedColumn = {
  name: string;
  navigation: NavigationItem[];
};

type Props = {
  columns: Column[] | undefined;
};

const NavigationFooter: React.FC<Props> = ({ columns }) => {
  const pharmacyInfo = usePharmacyData();
  const { findHospitalSearch, urgentCareSearch, doctorSearch } =
    useHealthTrio();

  const memberLinks: { [key: string]: string } = {
    'doctor-search': doctorSearch,
    'drug-list': pharmacyInfo.druglist || '',
    'urgent-care': urgentCareSearch,
    'find-a-hospital': findHospitalSearch,
  };

  const renderNavigationItem = (item: NavigationItem) =>
    (item.Url !== 'drug-list' ||
      (item.Url === 'drug-list' && pharmacyInfo.hasPharmacy)) && (
      <Link
        className="footer-navigation__link"
        to={
          memberLinks.hasOwnProperty(item.Url)
            ? memberLinks[item.Url]
            : item.Url
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.TitlesPath}
      </Link>
    );

  const processedColumns: ProcessedColumn[] =
    columns?.map((column: Column) => {
      return {
        name: column.Properties.CssClass,
        navigation: JSON.parse(column.Properties.SerializedSelectedPages),
      };
    }) || [];

  return (
    <div className="footer-navigation__row">
      {processedColumns
        .reverse()
        .map((column: ProcessedColumn, index: number) => (
          <div className="footer-navigation__column" key={index}>
            <h3 className="footer-navigation__column-heading">{column.name}</h3>
            <ul>
              {column.navigation.map((item: NavigationItem, index: number) => (
                <li key={index}>
                  {column.name !== 'Get Care' &&
                  column.name !== 'Obtener atención' ? (
                    <Link className="footer-navigation__link" to={item.Url}>
                      {item.TitlesPath}
                    </Link>
                  ) : (
                    renderNavigationItem(item)
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

export default NavigationFooter;
