import './CardPrimary.scss';
import { Link } from 'react-router-dom';
import IconRightArrow from '../IconRightArrow/IconRightArrow';
import IconOpenNewTab from '../IconOpenNewTab/IconOpenNewTab';

type CardPrimaryProps = {
  to: string;
  title: string;
  text?: string;
  openInNewTab?: boolean;
};

const CardPrimary = ({
  to,
  title,
  text,
  openInNewTab = false,
}: CardPrimaryProps) => {
  const linkProps = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : '';
  return (
    <Link className="card-primary" to={to} {...linkProps}>
      <span className="card-primary__content">
        <div className="card-primary__text-wrapper">
          <span className="card-primary__title">{title}</span>
          {text && <span className="card-primary__text">{text}</span>}
        </div>
      </span>

      <span className="card-primary__indicator">
        {(!openInNewTab && <IconRightArrow />) || <IconOpenNewTab />}
      </span>
    </Link>
  );
};

export default CardPrimary;
