import { useState } from 'react';
import './TabMenu.scss';

export interface Tab {
  tabTitleContent: JSX.Element;
  tabContent: JSX.Element[];
  index: number;
}

type Props = {
  tabs: Array<Tab>;
};

export default function TabMenu({ tabs }: Props) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <ol className="tab-menu">
        {tabs.map((tab, index) => (
          <li className="tab-menu__tab-item" key={'tab-menu-item-' + index}>
            <button
              className={`tab-item__title ${
                index === activeTabIndex ? 'active' : 'inactive'
              }`}
              data-index={index}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.tabTitleContent}
            </button>
          </li>
        ))}
      </ol>
      {tabs.map((tab, index) => (
        <div
          key={'tab-content-' + index}
          className={`tab-item__content ${
            index === activeTabIndex ? 'visible' : 'hidden'
          }`}
        >
          {tab.tabContent}
        </div>
      ))}
    </>
  );
}
