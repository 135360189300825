import { useEffect, useRef } from 'react';

type UseScrollToViewOptions = {
  id: string;
  block?: 'start' | 'center' | 'end' | 'nearest';
};

function useScrollToView({
  id,
  block = 'center',
}: UseScrollToViewOptions): React.RefObject<HTMLDivElement> {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash.substring(1) === id && elementRef.current) {
        elementRef.current.scrollIntoView({ block });
      }
    };

    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [id, block]);

  return elementRef;
}

export default useScrollToView;
