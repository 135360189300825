import './Faqs.scss';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { getRelatedCategories } from '../../api/faqApi';
import { FaqCategory, FaqCategoryTransform } from '../../interfaces/Faq';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Accordion from '../Accordion/Accordion';
import ErrorAlert from '../ErrorAlert/ErrorAlert';

type Props = {
  faqMainId: string;
  faqMainUrl: string;
};

const Faqs = ({ faqMainId, faqMainUrl }: Props) => {
  const { i18n } = useTranslation();
  const locale = i18n.resolvedLanguage ?? 'en';

  // Get Faq Categories
  const {
    data: faqCategories,
    isLoading,
    error,
  } = useQuery({
    queryKey: [`faq-categories-${faqMainUrl}`, locale],
    queryFn: () => getRelatedCategories(faqMainId, locale),

    select: data =>
      data.map((item: FaqCategory) => {
        return {
          id: item.Id,
          name: item.Name,
          url: item.UrlName,
        };
      }),
  });

  return (
    <section className="faqs" data-testid="faqs-section">
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorAlert />
      ) : faqCategories ? (
        <div className="faqs__list">
          {faqCategories.map(
            (category: FaqCategoryTransform, index: number) => (
              <Accordion key={index} id={index} category={category} />
            ),
          )}
        </div>
      ) : null}
    </section>
  );
};

export default Faqs;
