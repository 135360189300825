import './LoginWidget.scss';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import EpicIframe from '../EpicIframe/EpicIframe';
import PrimaryContainer from '../PrimaryContainer/PrimaryContainer';
import useLoginStore from '../../store/LoginStore';
import PrimaryIconLink from '../PrimaryIconLink/PrimaryIconLink';
import IconGuestPay from '../ShpIcons/IconGuestPay';
import useIsDevelopment from '../../hooks/useIsDevelopment';

export default function LoginWidget() {
  const isDevelopment = useIsDevelopment();
  const { t } = useTranslation();
  const { incorrectLogin } = useLoginStore();
  let loginWidgetUrl = 'Authentication/Login/LoginWidget?mobile=0';

  if (isDevelopment) {
    loginWidgetUrl = `${loginWidgetUrl}&postLoginUrl=sharp.html`;
  }

  return (
    <PrimaryContainer hasHelpModal={true} hasShpLogo={true}>
      <div className="login-widget" data-testid="login-widget">
        <div className="login-widget__data-tile">
          {incorrectLogin && (
            <p className="error login-widget__error" role="alert">
              <div className="login-widget__error-message">
                <Trans i18nKey="login.error">
                  There was a problem logging in with your username or password.
                  Please try again.
                </Trans>
              </div>
            </p>
          )}
          <EpicIframe
            title="SHP Member Portal Login"
            url={loginWidgetUrl}
            width={500}
            height={220}
            scrolling="no"
            isLogin={true}
          />
        </div>
        <div className="login-widget__reset">
          <Trans i18nKey="login.forgotCredentials">
            Forgot <Link to="recover-username">username</Link> or
            <Link to="reset-password">password</Link>?
          </Trans>
        </div>
        <div className="login-widget__signup">
          <p>
            <Trans i18nKey="login.signup">
              {' '}
              <Link to="code-request">Create an account</Link>
              or <Link to="code">use activation code</Link>
            </Trans>
          </p>
        </div>
        <div className="login-widget__guestpay">
          <PrimaryIconLink
            to={`guest-pay`}
            title={t('login.guestpay.button.title')}
            text={t('login.guestpay.button.description')}
            theme={'small'}
          >
            <IconGuestPay />
          </PrimaryIconLink>
        </div>
        <p className="login-widget__learn-more">
          <Trans i18nKey="login.learnMore">
            Learn more about
            <a
              href="https://www.sharphealthplan.com/"
              target="_blank"
              rel="noreferrer"
            >
              Sharp Health Plan
            </a>
          </Trans>
        </p>
      </div>
    </PrimaryContainer>
  );
}
