import './ManageCareBlock.scss';
import { useTranslation } from 'react-i18next';
import { ResourceLinkProps } from '../../interfaces/ResourceLink';
import TwoColumnCardGrid from '../TwoColumnCardGrid/TwoColumnCardGrid';
import ResourceLink from '../ResourceLink/ResourceLink';
import useEpicApiStore from '../../store/useEpicApiStore';

const ManageCareBlock = () => {
  const { t } = useTranslation();
  const { isPPO } = useEpicApiStore();

  const cardTranslationsKey = isPPO
    ? 'findCare.manageCare.cards.planSpecific.ppo'
    : 'findCare.manageCare.cards.general';

  const cards: ResourceLinkProps[] = t(cardTranslationsKey, {
    returnObjects: true,
  });

  return (
    <div className="manage-care">
      <h2 className="manage-care__title">{t('findCare.manageCare.title')}</h2>
      <TwoColumnCardGrid>
        <>
          {cards.map((link, index) => (
            <ResourceLink {...link} key={index} />
          ))}
        </>
      </TwoColumnCardGrid>
    </div>
  );
};

export default ManageCareBlock;
