import './CardTertiary.scss';

export interface CardContent {
  header: JSX.Element;
  body: JSX.Element[];
}

type Props = {
  content: CardContent;
};

export default function CardTertiary({ content }: Props) {
  return (
    <div className="card-tertiary">
      <div className="card-tertiary__header">{content.header}</div>
      <div className="card-tertiary__content">{content.body}</div>
    </div>
  );
}
