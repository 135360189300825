import { useTranslation } from 'react-i18next';
import { getChangeMyPlanForm } from '../../helpers/sharedLibrary';
import PrivateFormPage from '../PrivateFormPage/PrivateFormPage';
import useEpicApiStore from '../../store/useEpicApiStore';

const ChangeMyPlan = () => {
  const { t } = useTranslation();
  const { planInfo } = useEpicApiStore();
  let changeMyPlanForm = getChangeMyPlanForm(planInfo?.forms.coverage || [], t);

  if (!changeMyPlanForm && !changeMyPlanForm?.includes('/inside.aspx')) {
    return null;
  }

  if (changeMyPlanForm?.charAt(0) === '/') {
    changeMyPlanForm = changeMyPlanForm.substring(1);
  }

  return (
    <PrivateFormPage
      title={t('routes.coverage.changeMyPlan')}
      src={changeMyPlanForm}
      height={800}
    />
  );
};

export default ChangeMyPlan;
