import './PersonalUpdates.scss';
import { useTranslation } from 'react-i18next';
import CardPrimary from '../CardPrimary/CardPrimary';
import useEpicApiStore from '../../store/useEpicApiStore';
import { getAllForms } from '../../helpers/sharedLibrary';

type Props = {
  hasHeading?: boolean;
};

const PersonalUpdates = ({ hasHeading = false }: Props) => {
  const { t } = useTranslation();
  const { planInfo } = useEpicApiStore();

  const personalUpdateItems = getAllForms(planInfo?.forms.account || [], t);

  return personalUpdateItems.length === 0 ? null : (
    <div className="personal-updates">
      {hasHeading && (
        <h2 className="personal-updates__title">
          {t('account.personalUpdatesBlock.title')}
        </h2>
      )}

      <div className="personal-updates__cards">
        {personalUpdateItems.map((item, index) => (
          <CardPrimary
            to={item.link}
            title={item.title || ''}
            text={item.description}
            openInNewTab={item.newWindow}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default PersonalUpdates;
