import { useTranslation } from 'react-i18next';

const IconRightArrow = () => {
  const { t } = useTranslation();

  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{t('icon.rightArrow')}</title>
      <path
        d="M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z"
        fill="#00548E"
      />
    </svg>
  );
};

export default IconRightArrow;
