import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import DualCoverageCards from '../../components/DualCoverageCards/DualCoverageCards';
import { useTranslation } from 'react-i18next';

const ViewMyPlans = () => {
  const { t } = useTranslation();

  return (
    <BreadcrumbLayout pageTitle={t('routes.coverage.viewMyPlans')}>
      <div className="container view-my-plans">
        <DualCoverageCards />
      </div>
    </BreadcrumbLayout>
  );
};

export default ViewMyPlans;
