import { useState, useEffect } from 'react';

type UseBillingStatusProps = {
  dueDate: string | null;
};

const useBillingStatus = ({ dueDate }: UseBillingStatusProps): boolean => {
  const [isPastDue, setIsPastDue] = useState<boolean>(false);

  useEffect(() => {
    const currentDate = new Date();
    const dueDateObj = new Date(dueDate || '');

    dueDateObj.setDate(dueDateObj.getDate() + 1);

    setIsPastDue(currentDate >= dueDateObj);
  }, [dueDate]);

  return isPastDue;
};

export default useBillingStatus;
