import './PrimaryContainer.scss';
import { Link } from 'react-router-dom';
import HelpButton from '../HelpButton/HelpButton';
import PublicLanguageSelector from '../PublicLanguageSelector/PublicLanguageSelector';
import ShpLogo from '../ShpLogo/ShpLogo';

type PrimaryContainerProps = {
  children: JSX.Element;
  hasHelpModal?: boolean;
  hasShpLogo?: boolean;
};

export default function PrimaryContainer({
  children,
  hasHelpModal = false,
  hasShpLogo = false,
}: PrimaryContainerProps) {
  return (
    <div className="primary-container" data-testid="primary-container">
      {hasHelpModal ? (
        <div className="primary-container__top">
          <HelpButton />
          <PublicLanguageSelector />
        </div>
      ) : (
        ''
      )}

      {hasShpLogo ? (
        <div className="primary-container__logo">
          <Link to="/">
            <ShpLogo />
          </Link>
        </div>
      ) : (
        ''
      )}
      {children}
    </div>
  );
}
